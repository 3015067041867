.TituloCarruselProd {
    width: 60%;
    border-bottom: solid 2px var(--primaryColor);
    /* border-top: solid 3px var(--primaryColor); */
    font-size: 17px;
    letter-spacing: 3px !important;
    font-weight: bold;
    margin: auto;
    text-align: center;
    color: var(--primaryColor);
    margin-top: 10px;
    margin-bottom: 20px;
    padding-block: 10px;
    text-transform: uppercase;
}

.cSinBorde {
    /* border-top: unset; */
    border-bottom: unset;
}

.contenedorCarouselProd {
    width: 100%;
    margin: auto;
    margin-block: 10px;
    height: 100%;
    position: absolute;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
    background: transparent;
}

.ContenedorImagenesFijoProd {
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;
}

.ContenedorPaginacion {
    display: flex;
    flex-direction: row;
    height: auto;
    margin: auto;
    overflow: visible;
    height: 25px;
    align-items: center;
    padding: 5px;
    margin-top: 15px;
}

.itemPaginacion {
    display: flex;
    flex-direction: column;
    height: 20px;
    width: 20px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryColor);
    border-radius: 100px;
    margin: auto;
    margin-left: 4px;
    margin-right: 4px;
    overflow: visible;
    cursor: pointer;
    transition: all;
    transition-duration: .2s;
}

.cPaginaActiva,
.itemPaginacion:hover {
    overflow: visible;
    transform: scale(1.2);
    box-shadow: 0px 0px 1px 1px var(--primaryColor);
}

.ContenedorPaginacion .flecha{
    /* background-color: var(--secondaryColor); */
    background-color: #FFF;
    /* border: solid 0.5px var(--primaryColor); */
    color: var(--primaryColor);
    font-weight: 900;
}


.ContenedorImagenesMovilProd {
    width: 75%;
    height: auto;
    display: flex;
    flex-direction: row;
    overflow: visible;
    margin: auto;
    background-color: inherit;
    transition-duration: .5s;
    opacity: 1;
}

.cImgOcultoIzquierda {
    overflow: hidden;
    transition: all;
    transition-duration: .5s;
    /* Esto mueve el contenido a la izquierda
    left: -100%;
    top: 0px;
    position: absolute;
    z-index: 1;*/
    height: 0px;
    opacity: 0;
}

.cImgOcultoDerecha {
    overflow: hidden;
    transition: all;
    transition-duration: .5s;
    /* Esto mueve el contenido a la dereca
    left: 200%;
    top: 0px;
    position: absolute;
    z-index: 1;*/
    position: absolute;
    opacity: 0;
}

.ImagenProd {
    display: flex;
    flex-direction: column;
    margin: 0px;
    cursor: pointer;
    overflow: visible;
    width: 23%;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
    transition-duration: .3s;
}

.cAuxContenedorProd {
    display: flex;
    flex-direction: column;
    color: unset;
    text-decoration: unset;
    height: max-content;
    width: 100%;
    border-radius: 5px;
    border: solid 2px var(--primaryColor);
    border-radius: 3px;
}

.cRef {
    display: flex;
    flex-direction: column;
}

.ImagenProd:hover {
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -o-transform: scale(1.03);
    transform: scale(1.03);
    transition-property: transform;
    transition-duration: .3s;
}

.cAuxOfertafavoritos {
    height: 30px;
    width: 100%;
    display: flex;
    margin-top: 5px;
}

.cOfertaLabel {
    width: max-content;
    padding-left: 10px;
    padding-right: 15px;
    border-radius: 0px 100px 100px 0px;
    height: max-content;
    background: var(--secondaryColor);
    color: var(--letraSecondaryColor);;
    font-size: 13px;
    display: flex;
    margin: auto;
    margin-left: 0px;
}

.cIconoFavoritos {
    display: flex;
    margin: auto;
    margin-right: 3px;
    color: var(--primaryColor);
    font-size: 22px;

}

.imgProd {
    object-fit: fill;
    height: 130px;
    width: 75%;
    margin: 12.5%;
    margin-top: 10px;
}

.descripcionImagenProd {
    width: 100%;
    text-align: center;
    margin: auto;
    line-height: normal;
    display: flex;
    flex-direction: column;
}

.descripcionImagenProdCenter {
    color: var(--primaryColor);
    display: flex;
    width: 100%;
    margin: auto;
    margin-top: 6px;
    margin-bottom: 3px;
    height: 40px;
    font-size: 11px;
    overflow: hidden;
    overflow-y: auto;
    display: block;
}

.btnAgregarCarrito {
    width: 100%;
    overflow: visible;
}

.ImagenProd.oculta {
    height: 0px;
    opacity: 0;
}

@media (max-width: 900px) {
    .ContenedorImagenesMovilProd {
        width: 100%;
    }
}

@media (max-width: 625px) {
    .ContenedorImagenesMovilProd {
        width: 80%;
    }

    .ContenedorImagenesMovilProd {
        display: inline-grid;
        grid-template-columns: auto auto;
    }

    .ImagenProd {
        width: 50%;
        min-width: 150px;
        margin-bottom: 10px;
    }

    .imgProd {
        height: 110px;
    }

    .descripcionImagenProdCenter {
        font-size: 10px;
        width: 100%;
        height: max-content;
    }

    .descripcionImagenProd {
        width: 100%;
    }

    .btnAgregarCarrito {
        transform: scale(0.9);
    }
}

@media (max-width: 485px) {
    .ContenedorImagenesMovilProd {
        width: 90%;
    }
}

@media (max-width: 355px) {
    .descripcionImagenProdCenter {
        max-height: 35px;
    }
}

@media (max-width: 325px) {
    .ImagenProd {
        width: 50%;
        min-width: 120px;
        margin-bottom: 5px;
    }

    .imgProd {
        height: 95px;
    }

    .descripcionImagenProdCenter {
        font-size: 10px;
        width: 100%;
        height: max-content;
    }

    .descripcionImagenProd {
        width: 100%;
    }

    .btnAgregarCarrito {
        transform: scale(0.85);
    }
}

@media (max-width: 265px) {
    .ImagenProd {
        width: 50%;
        min-width: 100px;
        margin-bottom: 5px;
    }

    .imgProd {
        height: 85px;
    }
}