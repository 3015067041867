::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background: var(--mainColor);
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: gray;
}
.seguimiento ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
.contenidoDetCompra {
    background-color: white;
    width: 90%;
    height: 100%;
    display: flex;
    margin: auto;
    /* margin-bottom: 15px; */
    font-Family: '"Roboto", "Helvetica", "Arial", sans-serif',
}
.datosPedido {
    width: 60%;
    float: left;
    padding: 35px;
  
}
.listadoProductos {
    width: 40%;
    float: right;
    height: 100%;
    padding: 15px;
}
.estatus {
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    text-align: center;
    padding: 8px;
}
.resumen {
    border: solid 1px;
    border-color: var(--secondaryColor);
    border-radius: 5px;
    display: flex;
    padding: 5px 15px 5px 25px;
    width: 100%;
    color: var(--primaryColor);
    text-align: justify;
}
.datos {
    width: 100%;
    margin: auto;
    display: flex;
}
.campo {
    text-align: left;
    width: 100%;
}
.campo label {
    align-items: left;
}
.campo input {
    border-width: thin;
    border-style: solid;
    border-color: var(--secondaryColor);
    border-radius: 5px;
    padding: 6px;
    margin: 5px 0px 5px;
}
.seguimiento {
    width: 100%;
    max-width: 100vw;
    padding: 15px 25px 10px;
    overflow: auto;
    height: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--primaryColor) black;
    
    
}
.entrega {
    color: var(--primaryColor);
    padding: 15px;
    width: 60%;
    float: left;
}
.panel-botones {
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding: 15px;
    margin: auto;
    height: auto;
    color: var(--secondaryColor);
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    
}
.boton-reembolso {
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border: solid 1px var(--secondaryColor);
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 3px;
    width: 150px;
    height: 40px;

}
.boton-reembolso:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}
#iconoReembolso {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}
#iconoReembolso path {
    fill: var(--secondaryColor);
    width: 50px;
}
.boton-reembolso:hover #iconoReembolso path {
    fill: var(--colorLetraSecondaryColor);
    width: 50px;
}

.boton-regresar {
    padding: 5px;
    border: solid 1px var(--secondaryColor);
    border-radius: 30px;   
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;  
    justify-content: space-between;
    margin: 3px;
    width: 150px;
    height: 40px;

}

.boton-regresar:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.boton-regresar:hover #vectorArrowL polygon {
    fill: var(--colorLetraSecondaryColor);
}

.boton-regresar:hover #vectorRefund polygon {
    fill: var(--colorLetraSecondaryColor);
}
@media  screen and (max-width: 375px) {
    .contenidoDetCompra {
        flex-Direction: column;
        display: inline-block;
        width: 100%;
    }
    .datosPedido{
        padding: 15px;
        border-bottom: solid 2px;
        border-color: var(--primaryColor);
        border-right: unset;
    }
    .seguimiento {
        width: 100%;
        padding: 15px 5px 22px;
        overflow: auto;
    }
     
}

@media  screen and (max-width: 768px) {
    .contenidoDetCompra {
        flex-Direction: column;
        display: inline-block;
        width: 100%;
    }
    .seguimiento {
        width: 100%;
        padding: 15px 10px 22px;
    }
    .datosPedido {
        width: 100%;
        padding: 10px;
        border-right: 0ch;
        border-bottom: solid 2px;
        border-color: var(--primaryColor);
        padding: 25px;
    }
    .listadoProductos {
        width: 100%;
        height: 100%;
        border: unset;
    }
    .datos{       
        flex-Direction: column;
    }
    .panel-botones{
        display: block;         
    }
}


@media only screen and (min-width:769px) and (max-width: 992px) {
    .contenidoDetCompra {
        flex-Direction: row;
        width: 100%;
    }
    .seguimiento {
        width: 100%;
        padding: 15px 25px 22px;
    }
    .datosPedido {
        width: 100%;
        border-bottom: unset;
        border-right: solid 2px;
        border-color: var(--primaryColor);    
        padding: 25px;   
    }
    .listadoProductos {
        width: 100%;
    }
    .datos {       
        flex-Direction: row,
    }
    .panel-botones{
        display: flex;      
        justify-content: space-between;   
    }
}

@media screen and (min-width: 993px)  {
    .contenidoDetCompra {
        display: flex;
        flex-Direction: row;
        width: 90%;
    }
    .seguimiento {
        width: 100%;
        padding: 15px 25px 22px;
    }
    .datosPedido {
        width: 60%;
        border-bottom: unset;
        border-right: solid 2px;
        border-color: var(--primaryColor);
    }
    .listadoProductos {
        width: 40%;
    }    
    .datos {       
        flex-Direction: row,
    }
    .panel-botones{
        font-size: 14px;         
        justify-content: space-between;
        display: flex;
        
    }
}