
  .sec{
    position: relative;
  }
  .contenido-linea {  
    display: flex;
    justify-content: space-between;
    position: relative;   
    width: 100%;
    overflow-x:auto;  
  }
  .contenido-linea::before {
    content: '';
    background-color: gray;
    position: absolute;
    top: 30px;
    left: 0;
    transform: translateY(-80%);
    height: 4px;
    width: 98%;
    z-index: 0;
    transition: 0.4s ease;
    margin-left: 1px;
  }
  .linea{
    background-color: gray;
    position: absolute;
    top: 30px;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 0%;
    z-index: 0;
    transition: 0.4s ease;
  }
  .contenidoEtapa{
    display: flex;
    display: inline-block;
    width: 60px;
    height: auto;
    align-items: center;
    justify-content: center;
    cursor: help;
    font-size: 8px;
    transition: 0.4s ease;
    text-align: center;  
    z-index: 1;
  }
  .activo{
    background-color: silver;
    border-radius: 50%;
    width: 60px;
    height: 60px;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
  
  }
  .inactivo{
    background-color: silver;
    border-radius: 50%;
    width: 60px;
    height: 60px;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
  
  }  
   .sig{
    content: 'sdfs';  
    position: absolute; 
    border-radius: 5px;
    width: 120px;
    max-width: 1200px;;
    height: 5px;
    background-color: black;   
    top: 40%;
    left: 3px;
    margin-left: 3px; 
    
  }
  .datosFecha{     
    line-height: 14px;
    font-size: 10px;
    padding: 5px;    
    white-space: pre;
  }
  .datosFecha span{
    overflow-wrap: break-word;
  }
  @media  screen and (max-width: 992px) {
    .datosFecha{
      white-space: normal;
      overflow-wrap: break-word;
      font-size: 9px;
    }
    .activo{
      width: 65px;
      height:65px;
    }
    .inactivo{
      width: 65px;
      height:65px;
    }
    .contenidoEtapa{
      width: 70px;
    }
    .contenido-linea{
      width: max-content;
    }
    .datosFecha span{
      display: none;
    }
     
}

@media  screen and (max-width: 992px) {
    .datosFecha{
      white-space: normal;
      overflow-wrap: break-word;
      font-size: 9px;
    }
    .activo{
      width: 65px;
      height:65px;
    }
    .inactivo{
      width: 65px;
      height:65px;
    }
    .contenidoEtapa{
      width: 70px;
    }
    .contenido-linea{
      width: max-content;
    }
    .datosFecha span{
      display: none;
    }
     
}



  
 
  
