.pagePrincipal {
    background-color: #F8F9F9   ;
}
.tituloCorrecto {
    font-size: 20px;
    color: var(--secondaryColor); 
    font-weight: 600;
    padding-top: 30px;
    margin: auto;
}
.imagenCentro {
    padding: 10px;
}
@media (max-width: 600px) {
    .tituloCorrecto {
       font-size: 18px;
       padding-top: 20px;
    }
    
}
@media (max-width: 300px) {
    .tituloCorrecto {
       font-size: 16px;
       padding-top: 15px;
    }
    
}
