.contenedorFiltrosDepartamentos {
    width: 100%;
    height: auto;
}

.contenedorFiltrosCategorias {
    width: 100%;
    height: auto;
}

.contenedorListaFiltrosDepartamentos {
    margin-top: 10px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: left;
    list-style-type: none;
    font-size: 1vw;
    animation: growDown 1s;
}

.contenedorListaFiltrosDepartamentos label {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contenedorFiltrosDepartamentos label:hover {
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
}

.contenedorListaFiltrosCategorias {
    margin-top: 10px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: left;
    list-style-type: none;
    font-size: 1vw;
    animation: growDown 1s;
}

.contenedorListaFiltrosCategorias label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contenedorListaFiltrosCategorias label:hover {
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
}

.buttonFilter {
    cursor: pointer;
    border-bottom: 5px solid var(--primaryColor);
    width: 90%;
}

.contenedorGeneralTablaProductos {
    max-width: 1920px;
    height: auto;
    margin: 0 auto;
}

.contenedorInternoTablaProductos {
    display: grid;
    grid-template-columns: 800px;
    max-width: 1200px;
    height: auto;
    margin: 50px auto;
    justify-content: center;
    align-items: start;
}

.contenedorFiltros {
    width: 100%;
    height: auto;
}

.contenedorEncabezadoTablaProductos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0 auto;
    width: 90%;
}

.contenedorEncabezado {
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 50px;
    width: 100%;
    color: var(--primaryColor);
    border-bottom: 5px solid var(--primaryColor);
}

.contenedorTablaProductos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 30px;
    height: auto;
    justify-items: center;
    margin-top: 50px;
    width: 100%;
}

.cardProductos {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
}

.contenedorImagenPortadaProducto {
    width: 100%;
    height: 65%;
    border: 3px solid var(--primaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedorImagenPortadaProducto > img {
    padding: 5%;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    cursor: pointer;
}

.descripcionProducto {
    width: 80%;
    height: 20%;
    overflow-y: scroll;
    margin: 2% auto;
    text-align: center;
    color: var(--primaryColor);
    line-height: auto;
    display: flex;
  
    margin-bottom: 10px;
    
}

.buttonProducto {
    width: 80%;
    padding: 3%;
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
}

.buttonProducto:hover {
    filter: brightness(110%);
}

.listadosTablaProductos{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.listadosTablaProductos button{
    margin: 0 5px;
    border: none;
    background: var(--primaryColor);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: var(--letraPrimaryColor);
    cursor: pointer;
    font-weight: bold;
}

.listadosTablaProductos .paginaActiva{
    background: var(--secondaryColor);
}

.hide{
    display: none;
}

.listadosTablaProductos .flecha{
    /* background-color: var(--secondaryColor); */
    background-color: #FFF;
    /* border: solid 0.5px var(--primaryColor); */
    color: var(--primaryColor);
    font-weight: 900;
}

.cantidadRegistrosTablaProductos{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--primaryColor);
}

.cantidadRegistrosTablaProductos select{
    width: 30% ;
    color: var(--primaryColor);
}

.cEachControlPaginacion{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--secondaryColor);
    color: var(--letraPrimaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 0px 5px;
    cursor: pointer;
    font-weight: 600;
    user-select: none;
}


.cEachControlPaginacion:hover{
    filter: brightness(120%);
}


.cEachControlAnterior{
    color: var(--secondaryColor);
    cursor: pointer;
    margin-right: 5px;
    height: auto;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    user-select: none;
}

.cEachControlSiguiente{
    color: var(--secondaryColor);
    cursor: pointer;
    margin-left: 5px;
    height: auto;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    user-select: none;
}

.cEachControlAnterior:hover{
    filter: brightness(120px);
    background-color: var(--secondaryColor);
    color: var(--letraPrimaryColor);
}
.cEachControlSiguiente:hover{
    filter: brightness(120px);
    background-color: var(--secondaryColor);
    color: var(--letraPrimaryColor);
}

/*////////////////ANIMACIONES/////////////////////*/

@keyframes growDown {
    0% {
        transform: rotateX(-90deg);
    }
    70% {
        transform: rotateX(20deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}

/*////////////////ANIMACIONES/////////////////////*/

.favButton {
    width: 25px;
    height: 25px;
    position: absolute;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    top: 5%;
    right: 5%;
    transition: background 0.5s ease;
}


.filtroPrecios {
    width: 100%;
    margin: 0 auto;
    padding-inline-start: 40px;
}

.filtroPrecios input {
    width: 90%;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 5px;
    background: var(--primaryColor);
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.filtroPrecios input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: var(--secondaryColor);
    cursor: pointer;
  }

  .filtroPrecios input::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--secondaryColor);
    cursor: pointer;
  }

/*////////////////////MEDIAS///////////////////////////////*/

@media screen and (min-width: 300px) and (max-width: 480px) {
    .contenedorGeneralTablaProductos {
        max-width: 480px;
        height: auto;
    }

    .contenedorInternoTablaProductos {
        display: grid;
        grid-template-columns: 100%;
        max-width: 480px;
        height: auto;
        margin: 50px auto;
        justify-content: center;
        align-items: start;
    }
    .cardProductos {
        height: 320px;
    }

    .buttonProducto {
        width: 80%;
        font-size: 3vw;
    }

    .descripcionProducto {
        font-size: 2.5vw;
    
    }

    .contenedorTablaProductos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }

    .contenedorFiltros{
        font-size: 2.5vw;
    }

    .contenedorFiltrosDepartamentos label{
        font-size: 3vw;
    }

    .contenedorFiltrosCategorias label{
        font-size: 3vw;
    }

    .cEachControlSiguiente{
        display: none;
    }

    .cEachControlAnterior{
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    .descripcionProducto {
        font-size: 4vw;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .contenedorGeneralTablaProductos {
        max-width: 768px;
        height: auto;
    }

    .contenedorInternoTablaProductos {
        display: grid;
        grid-template-columns: 100%;
        max-width: 768px;
        height: auto;
        margin: 50px auto;
        justify-content: center;
        align-items: start;
    }
    .cardProductos {
        height: 320px;
    }

    .buttonProducto {
        width: 80%;
        font-size: 2vw;
    }

    .descripcionProducto {
        font-size: 2vw;
    }

    .cEachControlSiguiente{
        display: none;
    }

    .cEachControlAnterior{
        display: none;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .contenedorGeneralTablaProductos {
        max-width: 1024px;
        height: auto;
    }

    .contenedorInternoTablaProductos {
        display: grid;
        max-width: 1024px;
        height: auto;
        margin: 50px auto;
        justify-content: center;
        align-items: start;
    }

    .buttonProducto {
        width: 80%;
        padding: 3%;
        background-color: var(--primaryColor);
        color: var(--letraPrimaryColor);
        border-radius: 50px;
        text-align: center;
        cursor: pointer;
    }

    .cardProductos {
        height: 320px;
    }


}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .contenedorGeneralTablaProductos {
        max-width: 1200px;
        height: auto;
    }

    .contenedorInternoTablaProductos {
        display: grid;
        max-width: 1024px;
        height: auto;
        margin: 50px auto;
        justify-content: center;
        align-items: start;
    }

    .cardProductos {
        height: 320px;
    }

 
}

/*////////////////////MEDIAS///////////////////////////////*/
