.contenedorGeneralNotificacionCancelarTarjeta{
    background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}
.contenedorInternoNotificacionCancelarTarjeta{
    max-width: 500px;
    max-height: 540px;
    width: 500px;
    height: 250px;
    background-color: #F2F2F2;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}

.contenedorInternoNotificacionCancelarTarjeta h2{
    width: 80%;
    font-size: 90%;
    margin: 0 auto;
    text-align: center;
}
.agrupadorNotificacionesCancelarTarjeta{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.agrupadorNotificacionesCancelarTarjeta button{
    width: 40%;
    padding: 10px 0;
    background-color: var(--primaryColor);
    border: none;
    color: white;
    border-radius: 30px;
}

.agrupadorNotificacionesCancelarTarjeta button:hover{
    background-color: var(--secondaryColor);
}

@media screen and (min-width:320px) and (max-width: 480px) {}

@media screen and (min-width:481px) and (max-width: 768px) {}

@media screen and (min-width:769px) and (max-width: 1024px) {}

@media screen and (min-width:1025px) and (max-width: 1200px) {}