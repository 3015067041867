.cls-1 {
    fill: url(#linear-gradient);
}

.cls-2 {
    fill: url(#linear-gradient-2);
}

.cls-3 {
    fill: url(#linear-gradient-3);
}

#c_logo_animacion_softwone{
    animation: logoSoftwoneRotate 1.5s linear infinite;
    width: 100%;
}

.c_general_animacion{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(48deg, rgba(173, 173, 173, 0.439), rgba(175, 168, 168, 0.439));
  z-index: 50;
}

.c_contenedor_animacion{
    width: 250px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
}

.waviy {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 2%;
    font-size: 30px;
  }
  .waviy span {
    font-weight: 600;
    position: relative;
    display: inline-block;
    color: var(--primaryColor);
    text-transform: uppercase;
    animation: waviy 1.5s infinite;
    animation-delay: calc(.1s * var(--i));
    
  }
  @keyframes waviy {
    0%,40%,100% {
      transform: translateY(0)
    }
    20% {
      transform: translateY(-15px)
    }
  }

@keyframes logoSoftwoneRotate {
    0%{
        transform: rotateY(-180deg);
    }

    100%{
        transform: rotateY(180deg);
    }
}

/* Animacion 2 */

#c_indicadores_animacion{
}

.c_contenedor_animacion_2{
    width: 50%;
    max-width: 250px;
    position: relative;
    margin: 0 auto;
}
.i_contenedor_animacion_2{
}

.i_contenedor_animacion_2 rect{
    fill: transparent;
    position: relative;
    display: inline-block;
    color: var(--primaryColor);
    text-transform: uppercase;
    animation: indicadoresLoader 1.5s infinite;
    animation-delay: calc(.1s * var(--j));
}
.i_contenedor_logo_animacion_2{
    width: 100%;
    position: absolute;
    top: 0;
}

@keyframes indicadoresLoader {
    100% {
      fill: var(--secondaryColor);
    }
  }