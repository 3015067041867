
.principal {
  
    background-color: rgba(0, 0, 0, 0.5); 
    color: white; 
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%; 
    overflow: hidden;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    font-size: 16px;
    margin: auto;
    z-index: 1001;

}
.form {    
    width: 95%;
    max-width: 450px;
    height: auto;
    background-color:var(--secondaryColor);
    color: White;
    border-radius: 15px;
    padding: 10px;
    overflow-y: hidden;   
    margin: auto;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}
.textoResaltado {
    font-size:16px;
    color: white;
    padding: 15px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bold;
}
.texto {
    color: white !important;
    font-size:14px;
    padding: 25px;
    margin: auto;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
   
}
@media (max-width: 600px) {
    .texto {
        padding: 5px;
        font-size:12px;
    }
}
.formularioRecuperar{
    color: white;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    padding: 10px;
}
.inputCap {
    border-radius: 100px;
    color: white;
    padding-left: 25px;
    height: 40px;
    text-indent: 1.5em;
    border-color: white;
}
.inputCap::placeholder {
    opacity: 0.86;
    color: white;
    font-weight: 400;  
    padding-left: 25px; 
}
.regresar {
    padding-top: 15px;
    padding-bottom: 0px;
    cursor: pointer;
    text-decoration: underline;
}

.formularioRecuperar .contenedorcInput {
    margin-bottom: 20px;
}

.formularioRecuperar .iconoInput {
    position: absolute;
    top: 9px;
    left: 18px;
    border-radius: 100%;
    align-items: center;
    padding: 0px;
    height: 22px;
    width: 22px;
    display: flex;
    margin: auto;
    font-size: 22px;
    box-shadow: 0px 0px 0px 2px var(--letraPrimaryColor);
    overflow: visible;
}


.cContenedorBotonesLogin {
    width: 100%;
    text-align: center;  
    padding: unset;
    margin: 0px;
}


