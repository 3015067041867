.cRegistro .tituloModalForm {
  margin-bottom: 20px;
}

.cInputsRegistro {
  margin: auto;
  width: 75%;
}

.cInputRegistro {
  border-radius: 100px;
  color: var(--letraPrimaryColor);
  padding-left: 48px;
  height: 40px;
  border-color: var(--letraPrimaryColor) !important;
}

.cContenedorNotificaciones {
  height: max-content;
  padding-top: 20px;
  overflow: visible;
}

.cInputsRegistro .contenedorcInput {
  margin-bottom: 24px;
}

.cInputsRegistro .iconoInput {
  position: absolute;
  top: 9px;
  left: 18px;
  border-radius: 100%;
  align-items: center;
  padding: 0px;
  height: 22px;
  width: 22px;
  display: flex;
  margin: auto;
  font-size: 18px;
  box-shadow: 0px 0px 0px 2px var(--letraPrimaryColor);
  overflow: visible;
}

.cItemContacto input::placeholder {
  opacity: 0.6;
}

.cContenedorBotonesRegistro {
  width: 100%;
  text-align: center;
  margin-bottom: 30px
}

.cInputsRegistro input::-webkit-input-placeholder {
  opacity: 0.6;
  color: var(--letraPrimaryColor) !important;
}

.cInputsRegistro input:-moz-placeholder {
  opacity: 0.6;
  color: var(--letraPrimaryColor) !important;
}

.cContenedorBotonesRegistro .cBoton {
  min-width: 180px;
  margin-top: 10px;
}

@media (max-width: 450px) {
  .cInputsRegistro .contenedorcInput {
    margin-bottom: 18px !important;
  }
}