.contenedorcInput {
    display: flex;
    height: max-content;
    width: 100%;
    flex-direction: column;
    color: var(--colorLetraFondo);
    margin: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    position: relative;
}

.contenedorcInput:hover,
.contenedorcInput:focus {
    color: var(--colorLetraFondo);
}

.cInput {
    height: 30px;
    color: inherit;
    width: 100%;
    background: transparent;
    border: 2px solid var(--colorDisabled);
    border-radius: 3px;
    padding-left: 7px;
    padding-right: 7px;
    margin: auto;
}

.cInputBusqueda {
    height: 30px;
    border: 2px solid var(--primaryColor);
    border-radius: 50px;
    color: var(--primaryColor);
}

.cInput:hover,
.cInput:focus {
    border: 2px solid var(--primaryColor);
}

.tituloInput {
    text-transform: capitalize;
    font-size: 12px;
    padding-left: 2px;
}

.iconoInput {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    width: 30px;
    cursor: pointer;
}

.setLeft {
    left: 3px;
    right: unset;
}

.setRight {
    left: unset;
    right: 3px;
}

.cAuxInput {
    display: flex;
    position: relative;
    flex-direction: column;
}

.cMensajeErrorInput {
    color: var(--colorError);
    font-size: 13px;
    line-height: 100%;
    padding: 8px;
    background: var(--colorError);
    color: var(--letraPrimaryColor);
    font-weight: bold;
    border-radius: 5px;
    width: 98%;
    margin: auto;
    margin-top: 3px;
}

@media (max-width: 450px) {
    .contenedorcInput {
        width: 100% !important;
        text-align: left;
        margin: unset;
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .tituloInput {
        height: 17px;
        font-size: 10px;
    }
}