.contenedorGeneralNosotros{
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.bannerPrincipalNosotros{
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.bannerPrincipalNosotros > img{
    width: 100%;
    height: auto;
    object-fit: contain;
}

.textBannerPrincipalNosotros{
    font-size: 1vw;
}

.textBannerPrincipalNosotros h4{
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    margin: auto;
    text-align: center;
    padding: 20px ;
    font-weight: 600;
}

.textWhiteNosotros{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1vw;
}

.textWhiteNosotros h5{
    width: 100%;
    max-width: 40%;
    color: var(--primaryColor);
    text-align: justify;
    text-align-last: center ;
    margin: 5% auto;
    font-weight: 600;
}

.bannersNosotros{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 70% 30%;
    margin-bottom: 50px;
}

.contenedorImagenBanner{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contenedorImagenBanner img{
    width: 100%;
    height: auto;
}

.textBannerNosotros{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-rows: 30% 70%;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
}

.headerTextBannerNosotros{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondaryColor);
    color: var(--letraPrimaryColor);
    font-size: 1vw;
}

.bodyTextBannerNosotros{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    font-size: .75vw;
}

.bodyTextBannerNosotros h2{
    width: 80%;
    text-align: center;
    text-align-last: center;
}

.bannersNosotros ~ .bannersNosotros{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 70% 30%;
    margin-bottom: 50px;
    grid-auto-flow: dense;
    direction: rtl;
    
}

.bannersNosotros ~ .bannersNosotros .bodyTextBannerNosotros{
    direction: ltr;
}


@media screen and (min-width:300px) and (max-width: 480px) {

    .textBannerPrincipalNosotros{
        font-size: 4vw;
    }

    .textWhiteNosotros{
        font-size: 3vw;
    }

    .textWhiteNosotros h5{
        max-width: 60%;
    }

    .bannersNosotros{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 50px;
    }
    
    .contenedorImagenBanner{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--primaryColor);
    }
    
    .contenedorImagenBanner img{
        width: 50%;
        height: auto;
    }
    
    .textBannerNosotros{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-rows: 30% 70%;
        grid-template-columns: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .headerTextBannerNosotros{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--secondaryColor);
        color: var(--letraPrimaryColor);
        font-size: 2vw;
    }
    
    .bodyTextBannerNosotros{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        color: var(--letraPrimaryColor);
        font-size: 1.5vw;
    }
    
    .bodyTextBannerNosotros h2{
        width: 80%;
        text-align: center;
        text-align-last: center;
    }

    .bannersNosotros ~ .bannersNosotros{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 50px;
        grid-auto-flow: row;
        
    }

}

@media screen and (min-width:481px) and (max-width: 768px) {

    .textBannerPrincipalNosotros{
        font-size: 1.5vw;
    }

    .textWhiteNosotros{
        font-size: 1.5vw;
    }

    .bannersNosotros{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 50px;
    }
    
    .contenedorImagenBanner{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--primaryColor);
    }
    
    .contenedorImagenBanner img{
        width: 50%;
        height: auto;
    }
    
    .textBannerNosotros{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-rows: 30% 70%;
        grid-template-columns: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .headerTextBannerNosotros{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--secondaryColor);
        color: var(--letraPrimaryColor);
        font-size: 1vw;
    }
    
    .bodyTextBannerNosotros{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        color: var(--letraPrimaryColor);
        font-size: .75vw;
    }
    
    .bodyTextBannerNosotros h2{
        width: 80%;
        text-align: center;
        text-align-last: center;
    }

    .bannersNosotros ~ .bannersNosotros{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 50px;
        grid-auto-flow: row;
        
    }
}

@media screen and (min-width:769px) and (max-width: 1024px) {}

@media screen and (min-width:1025px) and (max-width: 1200px) {}