.c_general_direccion_entrega {
    width: 100%;
    height: auto;
}

.c_direccion_entrega_titulo {
    color: var(--secondaryColor);
    font-size: 16px;
}

.c_direccion_entrega_titulo h6 {
    font-weight: 600;
    margin-left: 10px;
    text-transform: none;
}

.c_direccion_entrega_checkbox_first {
    width: 95%;
    height: auto;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.c_first_checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primaryColor);
    cursor: pointer;
}


.c_first_checkbox h6{
    text-transform: none;
    cursor: pointer;
}

.c_first_checkbox input {
    margin: 0px 5px;
    cursor: pointer;
}

.c_campos_formulario {
    width: 95%;
    height: auto;
    margin: 0 auto;
}

.cf_fr {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
    align-items: flex-start;
}

.c_acciones_formulario_pedido {
    width: 95%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
}

.fr_calle_nombre_direccion {
    width: 45%;
}

.fr_calle_nombre_direccion label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_calle_nombre_direccion input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_calle_nombre_direccion input::placeholder {
    color: var(--primaryColor);
}

.fr_responsable {
    width: 45%;
}

.fr_responsable label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_responsable input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_responsable input::placeholder {
    color: var(--primaryColor);
}

.fr_cp {
    width: 45%;
}

.fr_cp label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_cp input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_cp input::placeholder {
    color: var(--primaryColor);
}

.fr_colonia {
    width: 45%;
}

.fr_colonia label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_colonia select {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_colonia select::placeholder {
    color: var(--primaryColor);
}

.fr_pais {
    width: 45%;
}

.fr_pais label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_pais input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_pais input::placeholder {
    color: var(--primaryColor);
}

.fr_estado {
    width: 45%;
}

.fr_estado label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_estado input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_estado input::placeholder {
    color: var(--primaryColor);
}

.fr_municipio {
    width: 45%;
}

.fr_municipio label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_municipio input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_municipio input::placeholder {
    color: var(--primaryColor);
}

.fr_ciudad {
    width: 45%;
}

.fr_ciudad label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_ciudad input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_ciudad input::placeholder {
    color: var(--primaryColor);
}

.fr_zona {
    width: 45%;
}

.fr_zona label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_zona input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_zona input::placeholder {
    color: var(--primaryColor);
}

.fr_tipo_asentamiento {
    width: 45%;
}

.fr_tipo_asentamiento label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_tipo_asentamiento input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_tipo_asentamiento input::placeholder {
    color: var(--primaryColor);
}

.fr_calle {
    width: 45%;
}

.fr_calle label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_calle input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_calle input::placeholder {
    color: var(--primaryColor);
}

.fr_numero_exterior {
    width: 45%;
}

.fr_numero_exterior label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_numero_exterior input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_numero_exterior input::placeholder {
    color: var(--primaryColor);
}

.fr_numero_interior {
    width: 45%;
}

.fr_numero_interior label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_numero_interior input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_numero_interior input::placeholder {
    color: var(--primaryColor);
}

.fr_entre_calle {
    width: 45%;
}

.fr_entre_calle label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_entre_calle input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_entre_calle input::placeholder {
    color: var(--primaryColor);
}

.fr_numero_interior {
    width: 45%;
}

.fr_numero_interior label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_numero_interior input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_numero_interior input::placeholder {
    color: var(--primaryColor);
}

.fr_y_calle {
    width: 45%;
}

.fr_y_calle label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_y_calle input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_y_calle input::placeholder {
    color: var(--primaryColor);
}

.fr_celular {
    width: 45%;
}

.fr_celular label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_celular input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_celular input::placeholder {
    color: var(--primaryColor);
}

.fr_tel_particular {
    width: 45%;
}

.fr_tel_particular label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_tel_particular input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_tel_particular input::placeholder {
    color: var(--primaryColor);
}

.fr_tel_oficina {
    width: 45%;
}

.fr_tel_oficina label h6 {
    margin: 0;
    padding: 0 10px;
    color: var(--secondaryColor);
    text-transform: none;
}

.fr_tel_oficina input {
    border: 2px solid var(--secondaryColor);
    height: 40px;
    width: 100%;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 16px;
}

.fr_tel_oficina input::placeholder {
    color: var(--primaryColor);
}

.button_regresar_formulario_pedido {
    border: 2px solid var(--secondaryColor);
    padding: 5px 10px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.button_regresar_formulario_pedido h6 {
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-transform: none;
}

.button_regresar_formulario_pedido:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.button_regresar_formulario_pedido:hover #vectorArrowL polygon{
    fill: var(--colorLetraSecondaryColor);
}

.button_continuar_formulario_pedido {
    border: 2px solid var(--secondaryColor);
    padding: 5px 10px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.button_continuar_formulario_pedido h6 {
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.button_continuar_formulario_pedido:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.button_continuar_formulario_pedido:hover #vectorArrowR polygon{
    fill: var(--colorLetraSecondaryColor);
}

.c_seleccion_direccion {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 16px;
}

.c_seleccion_direccion select {
    margin: 20px auto;
    width: 100%;
    height: 50px;
    border-radius: 30px;
    padding: 0px 10px;
    border: 2px solid var(--secondaryColor);
}

.c_seleccion_direccion h6 {
    text-align: center;
    color: var(--primaryColor);
}

.c_seleccion_direccion h6 b {
    cursor: pointer;
}

.c_seleccion_direccion h6 b:hover {
    color: var(--secondaryColor);
}

.c_titulo_formulario {
    width: 90%;
    font-size: 16px;
    margin: 20px auto;
}

.c_titulo_formulario h6 {
    margin: 0 auto;
    text-align: center;
    font-size: 90%;
    color: var(--primaryColor);
    font-weight: 800;
}

.c_seleccion_sucursal {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 16px;
}

.c_seleccion_sucursal textarea {
    margin: 20px auto;
    width: 80%;
    height: 80px;
    border-radius: 10px;
    padding: 0px 10px;
    border: 2px solid var(--secondaryColor);
    overflow: scroll;
    resize: none;
}

.c_acciones_formulario_agregar_direccion {
    width: 95%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
}

.button_regresar_seccion {
    border: 2px solid var(--secondaryColor);
    padding: 10px 10px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    width: 35%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_regresar_seccion h6{
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}


.button_regresar_seccion:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.button_regresar_seccion:hover #vectorArrowL polygon{
    fill: var(--colorLetraSecondaryColor);

}

.button_guardar_direccion {
    border: 2px solid var(--secondaryColor);
    padding: 10px 10px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    width: 35%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_guardar_direccion:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.button_guardar_direccion:hover #vectorSaveIcon path{
    stroke: var(--colorLetraSecondaryColor);
}

.button_guardar_direccion:hover #vectorSaveIcon rect{
    stroke: var(--colorLetraSecondaryColor);
}

.button_guardar_direccion:hover #vectorSaveIcon line{
    stroke: var(--colorLetraSecondaryColor);
}


.button_guardar_direccion h6{
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.c_seleccion_direccion {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    font-size: 16px;
}

.c_seleccion_direccion textarea {
    margin: 20px auto;
    width: 80%;
    height: 80px;
    border-radius: 10px;
    padding: 0px 10px;
    border: 2px solid var(--secondaryColor);
    overflow: scroll;
    resize: none;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    label {
        width: 90%;
    }

    .c_titulo_formulario h6 {
        margin: 0 auto;
        text-align: center;
        font-size: 90%;
        color: var(--primaryColor);
        font-weight: 800;
    }

    .cf_fr {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin: 10px auto;
        flex-direction: column;
        align-items: center;
    }

    .fr_calle_nombre_direccion {
        width: 90%;
        margin-bottom: 10px;
    }

    .fr_calle_nombre_direccion input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_responsable {
        width: 90%;
    }

    .fr_responsable input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_cp {
        width: 90%;
        margin-bottom: 10px;
    }

    .fr_cp input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_colonia {
        width: 90%;
    }
    .fr_colonia select {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_pais {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_pais input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_estado {
        width: 90%;
    }
    .fr_estado input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_municipio {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_municipio input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_ciudad {
        width: 90%;
    }
    .fr_ciudad input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_zona {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_zona input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_tipo_asentamiento {
        width: 90%;
    }
    .fr_tipo_asentamiento input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_calle {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_calle input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_numero_exterior {
        width: 90%;
    }
    .fr_numero_exterior input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_numero_interior {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_numero_interior input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_entre_calle {
        width: 90%;
    }
    .fr_entre_calle input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_numero_interior {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_numero_interior input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_y_calle {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_y_calle input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_celular {
        width: 90%;
    }
    .fr_celular input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_tel_particular {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_tel_particular input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_tel_oficina {
        width: 90%;
    }
    .fr_tel_oficina input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .c_acciones_formulario_agregar_direccion{
        max-width: 480px;
        width: 95%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
    }

    .button_regresar_seccion{
        border: 2px solid var(--secondaryColor);
        padding: 5px 10px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
        font-size: 90%;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button_regresar_seccion h6{
        font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
    }


    .button_guardar_direccion {
        border: 2px solid var(--secondaryColor);
        padding: 5px 10px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
        font-size: 90%;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button_guardar_direccion h6{
        font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    label {
        width: 90%;
    }

    .c_titulo_formulario h6 {
        margin: 0 auto;
        text-align: center;
        font-size: 90%;
        color: var(--primaryColor);
        font-weight: 800;
    }

    .cf_fr {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px auto;
        flex-direction: column;
    }

    .fr_calle_nombre_direccion {
        width: 90%;
        margin-bottom: 10px;
    }

    .fr_calle_nombre_direccion input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_responsable {
        width: 90%;
    }

    .fr_responsable input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_cp {
        width: 90%;
        margin-bottom: 10px;
    }

    .fr_cp input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_colonia {
        width: 90%;
    }
    .fr_colonia select {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_pais {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_pais input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_estado {
        width: 90%;
    }
    .fr_estado input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_municipio {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_municipio input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_ciudad {
        width: 90%;
    }
    .fr_ciudad input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_zona {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_zona input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_tipo_asentamiento {
        width: 90%;
    }
    .fr_tipo_asentamiento input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_calle {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_calle input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_numero_exterior {
        width: 90%;
    }
    .fr_numero_exterior input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_numero_interior {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_numero_interior input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_entre_calle {
        width: 90%;
    }
    .fr_entre_calle input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_numero_interior {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_numero_interior input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_y_calle {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_y_calle input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_celular {
        width: 90%;
    }
    .fr_celular input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_tel_particular {
        width: 90%;
        margin-bottom: 10px;
    }
    .fr_tel_particular input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .fr_tel_oficina {
        width: 90%;
    }
    .fr_tel_oficina input {
        border: 2px solid var(--secondaryColor);
        height: 25px;
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 16px;
    }

    .c_acciones_formulario_agregar_direccion{
        max-width: 480px;
        width: 95%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
    }

    .button_regresar_seccion{
        border: 2px solid var(--secondaryColor);
        padding: 10px 10px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
        font-size: 90%;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }



    .button_guardar_direccion {
        border: 2px solid var(--secondaryColor);
        padding: 10px 10px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
        font-size: 90%;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    label {
        width: 90%;
    }

    .c_titulo_formulario h6 {
        margin: 0 auto;
        text-align: center;
        font-size: 90%;
        color: var(--primaryColor);
        font-weight: 800;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {

    label {
        width: 90%;
    }

    .c_titulo_formulario h6 {
        margin: 0 auto;
        text-align: center;
        font-size: 90%;
        color: var(--primaryColor);
        font-weight: 800;
    }
}
