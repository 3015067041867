.cContenedorHeader {
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cContenedorHeaderV1 {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 95%;
}

.cContenedorHeaderMenuLineal {
  width: 100%;
  background: var(--primaryColor);
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.cContenedorHeaderMenuLinealAux {
  width: 90%;
  display: flex;
  margin: auto;
  z-index: 2;
}

.cContenedorHeaderLogo {
  display: flex;
  margin: auto;
  margin-left: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 8px;
  padding-right: 8px;
}

.cHeaderLogoMenu {
  display: flex;
  margin: auto;
  width: 80%;
}

.cContenedorHeaderBusqueda {
  display: flex;
  margin: auto;
  width: 60%;
}

.cContenedorHeaderMenu {
  display: flex;
  margin: auto;
  margin-right: 0px;
  width: max-content;
  z-index: 3;
  position: relative;
}

.cLabelMonto {
  display: flex;
  margin: auto;
  padding-left: 4px;
  padding-right: 4px;
  color: var(--primaryColor);
}

.cLabelAux {
  display: flex;
  width: max-content;
  margin: auto;
}

.cNotificacionBoton {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  color: var(--primaryColor);
  font-size: 10px;
  bottom: 0px;
}

.cContenedorSesion {
  display: flex;
  font-size: 11px;
  font-weight: bold;
  color: var(--primaryColor);
  cursor: pointer;
}

.cRegistrarse,
.cIniciarSesion {
  display: flex;
  margin: auto;
  width: max-content;
  padding-left: 4px;
  padding-right: 4px;
}

.cRegistrarse{
  box-shadow: 6px 0px 0px -5px var(--primaryColor);
}

.cRegistrarse:hover,
.cIniciarSesion:hover {
  color: var(--secondaryColor);
  text-decoration: underline;
}

.c_menu_button{
  display: none;
}

@media (max-width: 768px) {

  .cContenedorHeader {
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 2;
}

  .cContenedorHeaderV1 {
    flex-direction: column;
    width: 100%;
  }

  .cContenedorHeaderLogo {
    width: 100%;
    margin: auto;
  }

  .c_menu_button{
    display: flex;
    width: 10%;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .cHeaderLogoMenu {
    width: 100%;
  }

  .cContenedorHeaderBusqueda {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .cContenedorHeaderMenu {
    display: flex;
    margin: auto;
    margin-right: 5px;
    width: max-content;
    z-index: 2;
  }
}

@media (max-width: 500px) {
  .cHeaderLogoMenu {
    flex-direction: column;
  }

  .cContenedorHeaderMenu {
    margin: auto;
  }
}

@media (max-width: 400px) {
  .cContenedorHeaderMenuLinealAux {
    width: 100%;
  }
}

@media(max-width: 768px){
  .cContenedorHeaderMenuLineal{
  display: none;
  }
}

@media (max-width: 300px) {
  .cHeaderLogoMenu {
    flex-direction: column;
  }

  .cContenedorHeaderBusqueda {
    width: 98%;
    margin: auto;
  }

  .cContenedorHeaderBusqueda {
    width: 98%;
    margin: auto;
  }

  .cContenedorHeaderMenu {
    width: max-content;
    margin: auto;
  }

  /* .cContenedorSesion{
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  } */
  .cRegistrarse,
  .cIniciarSesion {
    margin-left: 3px;
    margin-right: 3px;
  }
}