.contenedorGeneralPopUpSuscribirse{
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.contenedorInternoPopUpSuscribirse{
    max-width: 500px;
    max-height: 350px;
    width: 500px;
    height: 250px;
    background-color: var(--primaryColor);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    text-align: center;
}

.tituloPopUpSuscribirse{
    color: var(--color-Verde);
    font-size: 25px;
    width: 80%;
}

.descripcionPopUpSuscribirse{
    color: var(--letraPrimaryColor);
    font-size: 16px;
    width: 60%;
    font-weight: 600;
}

.inputCorreoPopUpSuscribirse{
    border-radius: 30px;
    border: 2px solid var(--color-Azul);
    width: 60%;
    height: 40px;
    color: var(--color-Azul);
    font-size: 16px;
    padding-left: 10%;
    padding-right: 2%;
    outline: none;
}

.inputCorreoPopUpSuscribirse::placeholder{
    color: var(--color-Azul);
    font-weight: 600;
}

.buttonEnviarPopUpSuscribirse{
    color: white;
    border: 2px solid var(--secondaryColor);
    width: 20%;
    height: 30px;
    border-radius: 30px;
    background-color: var(--secondaryColor);
    cursor: pointer;
    transition-property: all;
    transition-duration: .2s;
}

.buttonEnviarPopUpSuscribirse:hover{
    box-shadow:var(--boxShadowMin);
}

.buttonRegresarPopUpSuscribirse{
    border: none;
    color: var(--color-Azul);
    cursor: pointer;
    background-color: transparent;
    width: 20%;
    height: 30px;
    border-radius: 30px;
}

.buttonRegresarPopUpSuscribirse:hover{
    background-color: white;
    color: var(--color-Azul);
}

.cMsjErrSuscrip{
    color: var(--colorError);
    background: var(--colorError);
    color: var(--letraPrimaryColor);
    font-weight: bold;
    border-radius: 5px;
    margin-top: 3px;
    width: 100%;
}

.cMsjSucceSuscrip{
    color: var(--colorSuccess);
    background: var(--colorSuccess);
    color: var(--letraPrimaryColor);
    font-weight: bold;
    border-radius: 5px;
    margin-top: 3px;
    width: 100%;
}