.cIniciaSesion .tituloModalForm {
  margin-bottom: 35px;
}

.cInputsLogin {
  margin: auto;
  width: 75%;
}

.cInputLogin {
  border-radius: 100px;
  color: var(--letraPrimaryColor);
  padding-left: 48px;
  height: 40px;
  border-color: var(--letraPrimaryColor) !important;
}

.cInputsLogin .contenedorcInput {
  margin-bottom: 20px;
}

.cInputsLogin .iconoInput {
  position: absolute;
  top: 9px;
  left: 18px;
  border-radius: 100%;
  align-items: center;
  padding: 0px;
  height: 22px;
  width: 22px;
  display: flex;
  margin: auto;
  font-size: 22px;
  box-shadow: 0px 0px 0px 2px var(--letraPrimaryColor);
  overflow: visible;
}

.cInputsLogin input::placeholder {
  opacity: 0.86;
}

.cInputsLogin input::-webkit-input-placeholder {
  opacity: 0.6;
  color: var(--letraPrimaryColor) !important;
}

.cInputsLogin input:-moz-placeholder {
  opacity: 0.6;
  color: var(--letraPrimaryColor) !important;
}

.cContenedorBotonesLogin {
  width: 100%;
  text-align: center;
  margin-bottom: 30px
}

.cContenedorBotonesLogin .cBoton {
  min-width: 180px;
  margin-top: 10px;
}

.cOlvidasteContra {
  margin: auto;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  width: max-content;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--letraPrimaryColor);
}

.cCrearCuenta {
  margin: auto;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--secondaryColor);
  cursor: pointer;
  width: max-content;
}
.cCrearCuenta:hover,
.cCrearCuenta:focus,
.cOlvidasteContra:hover,
.cOlvidasteContra:focus {
  text-decoration: underline;
}