.cContenedorContacto {
    display: flex;
    flex-direction: column;
    background: var(--backgroundColor);
}

.cBannerContacto {
    display: flex;
    position: relative;
    width: 100%;
}

.cImgBannerContacto {
    width: 100%;
}

.cContenidoBanner {
    display: flex;
    width: 50%;
    position: absolute;
    height: 100%;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 5%;
}

.cLogoBannerContacto {
    width: auto;
    height: 30%;
    max-height: 60px;
    min-height: 60px;
    margin: auto;
    margin-bottom: 0px;
}

.cContenidoTextoBanner {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 48px;
    color: var(--letraPrimaryColor);
    text-align: center;
    margin: auto;
    margin-top: 50px;
    line-height: 110%;
}

.cTextoContactanos {
    width: 100%;
    text-align: center;
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    padding-top: 15px;
    padding-bottom: 15px;
    letter-spacing: 2px !important;
    font-size: 18px;
}

.cSeccionFormularioContacto {
    width: 80%;
    margin: auto;
    max-width: 800px;
    padding-top: 30px;
    padding-bottom: 50px;
}

.cInstruccionesContacto {
    padding-top: 20px;
    padding-bottom: 50px;
    color: var(--primaryColor);
    text-align: center;
    font-size: 22px;
    line-height: 120%;
}

.cFormularioContacto {
    width: 100%;
    margin: auto;
    max-width: 800px;
}

.cInputContacto {
    width: 98%;
    margin: auto;
    border-radius: 100px;
    color: var(--primaryColor);
    padding-left: 18px;
    height: 30px;
    border-color: var(--primaryColor) !important;
}

.cInputContacto.textarea {
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--primaryColor);
    font-size: 14px;
    height: 80px;
    resize: none;
}

#mensajeTextArea::-webkit-input-placeholder {
    color: var(--primaryColor);
}

#mensajeTextArea:-moz-placeholder {
    /* Firefox 18- */
    color: var(--primaryColor);
}

#mensajeTextArea::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--primaryColor);
}

#mensajeTextArea:-ms-input-placeholder {
    color: var(--primaryColor);
}

#mensajeTextArea::placeholder {
    color: var(--primaryColor);
}

.cInputsRegistro input::placeholder,
#mensajeTextArea::placeholder {
    opacity: 0.6 !important;
}

.cItemContacto {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding-top: 7px;
    padding-bottom: 7px;
}

#btnEnviarContacto:hover,
#btnEnviarContacto:focus{
    box-shadow: 0px 0px 2px 1px var(--secondaryColor);
}

.cBotonContacto{
    margin-top: 20px;
    margin-bottom: 10px;
}

@media (max-width: 1000px) {
    .cLogoBannerContacto {
        min-height: 50px;
    }

    .cContenidoTextoBanner {
        font-size: 40px;
        margin-top: 20px;
    }
}

@media (max-width: 800px) {
    .cLogoBannerContacto {
        min-height: 40px;
    }

    .cContenidoTextoBanner {
        font-size: 30px;
        margin-top: 20px;
    }

    .cContenidoBanner {
        width: max-content;
        margin-left: 3%;
    }

    .cInstruccionesContacto {
        font-size: 20px;
    }

    .cTextoContactanos {
        font-size: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media (max-width: 650px) {
    .cContenidoTextoBanner {
        font-size: 26px;
    }

    .cSeccionFormularioContacto {
        width: 90%;
    }

    .cInstruccionesContacto {
        font-size: 16px;
    }
}

@media (max-width: 450px) {
    .cLogoBannerContacto {
        min-height: 35px;
    }

    .cContenidoTextoBanner {
        font-size: 22px;
        margin-top: 5px;
    }

    .cContenidoBanner {
        width: max-content;
        margin-left: 1%;
    }

    .cSeccionFormularioContacto{
        width: 97%;
    }
    .cInstruccionesContacto {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 25px;
    }
    
    .cTextoContactanos {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .cItemContacto{
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

@media (max-width: 350px) {
    .cLogoBannerContacto {
        min-height: 30px;
    }

    .cContenidoTextoBanner {
        font-size: 18px;
        margin-top: 3px;
    }

    .cContenidoBanner {
        width: max-content;
        margin-left: 1%;
    }

    .cBotonContacto {
        margin-top: 15px;
        margin-bottom: 0px;
    }

    .cSeccionFormularioContacto{
        padding-top: 10px;
        padding-bottom: 10px;
    }
}