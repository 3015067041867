.contenedorFooter {
    /* margin-top: 30px; */
    width: 100%;
    height: auto;
    min-height: 80px;
    display: flex;
    background: var(--primaryColor);
    color: var(--letraPrimaryColor);
    flex-direction: column;
    text-align: center;
    font-size: 13px;
    position: relative;
}

.contenedorFooterAux {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-top: 35px;
    padding-bottom: 35px;
}

.contenedorFooterDerechos {
    background-color: var(--secondaryColor);
    color: var(--letraSecondaryColor);
    width: 100%;
}

.cLogoFooter {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 25%;
    height: fit-content;
    margin: auto;
    margin-left: 0px;
}

.cSusFooter {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 400px;
    margin: auto;
    color: var(--letraPrimaryColor);
    font-size: 13px;
}

.cLabelSus, .cLabelDown {
    width: max-content;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 13px;
}

.cLabelDown{
    margin-top: 20px;
    inline-size: 100%;
    overflow-wrap: break-word;
}

.cBtnsDescargar{
    display:inline-flex;
    flex-wrap: wrap;
}

.cBtnSus, .cBtnDown {
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 100px;
    /*box-shadow: 0px 0px 0px 2px white;*/
    border: 2px solid var(--letraPrimaryColor);
    color: var(--letraPrimaryColor);
    cursor: pointer;
}

.cBtnSus:hover, .cBtnDown:hover {
    box-shadow: 0px 0px 4px 3px var(--letraPrimaryColor);
    transform: scale(1.02);
}

.cTituloBtnSus, .cTituloBtnDown {
    width: max-content;
    margin: auto;
}

.cBtnDown{
    display: inline-flex;
    margin-inline: 5px;
    width: 40%;
    border-radius: 20px;
}

.cLogoPDF{
    margin-left: 5px;
    height: 24px;
    width: auto;
}

.cBtnDown, .cBtnDown:link, .cBtnDown:visited, .cBtnDown:active { 
    color: var(--letraPrimaryColor); 
}

.cBtnDown:hover{
    color: var(--secondaryColor)
}


.cInfoContactoFooter {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
    margin: auto;
    margin-right: 0px;
    align-items: flex-end;
}

.cInfoContactoFooterAux {
    display: flex;
    flex-direction: row;
    width: max-content;
    margin: auto;
    margin-right: 0px;
}

.cIcoContactoFooter {
    border-radius: 100px;
    width: 32px;
    height: 32px;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
    transition-duration: .2s;
}

.cIcoContactoFooter:hover,
.cIcoContactoFooter:active {
    transform: scale(1.15)
}

@media (max-width: 660px) {
    .cSusFooter {
        width: 35%;
    }

    .cLabelSus, .cLabelDown  {
        font-size: 12px;
    }

    .cBtnSus {
        font-size: 11px;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

@media (max-width: 600px) {
    .contenedorFooterAux {
        flex-direction: column-reverse;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 0px;
    }

    .cLogoFooter,
    .cSusFooter,
    .cInfoContactoFooter {
        width: 100%;
        margin: auto;
        transform: scale(0.85);
        margin-top: 0px;
        margin-bottom: 8px;
    }

    .cInfoContactoFooterAux {
        margin: auto;
    }

    .cInfoContactoFooter {
        width: 100%;
    }
}