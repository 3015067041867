.c_general_formualario_pedido_nuevo {
    max-width: 1920px;
    width: 100%;
    height: auto;
    margin: 20px auto;
}

.c_interno_formulario_pedido_nuevo {
    max-width: 1200px;
    width: 100%;
    height: auto;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 5fr 1px 3fr;
    min-height: 400px;
}

.c_general_preview_carrito_pedido_det {
    margin: 20px auto;
    width: 100%;
}

.c_each_preview_carrito_pedido {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    border-bottom: 2px solid var(--primaryColor);
    width: 90%;
    margin: 0 auto;
}

.c_carrito_pedido_imagen {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 1 / 3;
}

.c_carrito_pedido_imagen img {
    width: 80%;
    height: auto;
    object-fit: contain;
}

.c_carrito_nombre_producto {
    color: var(--primaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90%;
}

.c_carrito_nombre_producto h6 {
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.c_carrito_subtotal {
    color: var(--secondaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90%;
}

.c_carrito_subtotal h6 {
    font-weight: 800;
    width: 100%;
    text-align: center;
}

.c_informacion_totales {
    width: 85%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 4fr 1fr;
    font-size: 90%;
}

.c_informacion_totales h6:nth-child(odd) {
    color: var(--primaryColor);
    font-weight: 800;
}

.c_informacion_totales h6:nth-child(even) {
    color: var(--secondaryColor);
    font-weight: 800;
    text-align: center;
}

.separador {
    width: 90%;
    border-bottom: 2px solid var(--primaryColor);
    margin: 0 auto;
}

.c_total_pedido {
    width: 85%;
    margin: 0 auto;
    display: grid;
    font-size: 90%;
    grid-template-columns: 4fr 1fr;
}

.c_total_pedido h6:nth-child(odd) {
    color: var(--primaryColor);
    font-weight: 800;
}

.c_total_pedido h6:nth-child(even) {
    color: var(--secondaryColor);
    font-weight: 800;
    text-align: center;
}

.c_button_pagar_formulario_pedido {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}

.button_pagar_formulario_pedido {
    border: 2px solid var(--secondaryColor);
    padding: 5px 30px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.button_pagar_formulario_pedido h6 {
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.button_pagar_formulario_pedido:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.button_pagar_formulario_pedido:hover #vectorArrowR polygon {
    fill: var(--colorLetraSecondaryColor);
}

.division {
    border-left: 3px solid var(--primaryColor);
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .c_general_formualario_pedido_nuevo {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_formulario_pedido_nuevo {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 1fr;
        min-height: 400px;
    }

    .division {
        border-bottom: 5px solid var(--primaryColor);
    }

    .c_general_preview_carrito_pedido_det {
        margin: 20px auto;
        max-width: 480px;
        width: 100%;    
    }

    .c_each_preview_carrito_pedido {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
        border-bottom: 2px solid var(--primaryColor);
        width: 90%;
        margin: 0 auto;
    }

    .c_carrito_pedido_imagen {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 1 / 3;
    }

    .c_carrito_nombre_producto {
        color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_carrito_nombre_producto h6 {
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    .c_carrito_subtotal {
        color: var(--secondaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_informacion_totales {
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 4fr 1fr;
        font-size: 90%;
    }

    .c_total_pedido {
        width: 85%;
        margin: 0 auto;
        display: grid;
        font-size: 90%;
        grid-template-columns: 4fr 1fr;
    }

    .button_pagar_formulario_pedido {
        border: 2px solid var(--secondaryColor);
        padding: 5px 30px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .c_general_formualario_pedido_nuevo {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_formulario_pedido_nuevo {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 1fr;
        min-height: 400px;
    }

    .division {
        border-bottom: 5px solid var(--primaryColor);
    }

    .c_general_preview_carrito_pedido_det {
        margin: 20px auto;
        max-width: 768px;
        width: 100%;
    }

    .c_each_preview_carrito_pedido {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
        border-bottom: 2px solid var(--primaryColor);
        width: 90%;
        margin: 0 auto;
    }

    .c_carrito_pedido_imagen {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 1 / 3;
    }

    .c_carrito_nombre_producto {
        color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_carrito_nombre_producto h6 {
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    .c_carrito_subtotal {
        color: var(--secondaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_informacion_totales {
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 4fr 1fr;
        font-size: 90%;
    }

    .c_total_pedido {
        width: 85%;
        margin: 0 auto;
        display: grid;
        font-size: 90%;
        grid-template-columns: 4fr 1fr;
    }

    .button_pagar_formulario_pedido {
        border: 2px solid var(--secondaryColor);
        padding: 5px 30px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .c_general_formualario_pedido_nuevo {
        max-width: 1024px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_formulario_pedido_nuevo {
        max-width: 1024px;
        width: 100%;
        height: auto;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 5fr 1px 3fr;
        min-height: 400px;
    }

    .c_general_preview_carrito_pedido_det {
        margin: 20px auto;
        max-width: 1024px;
        width: 100%;
      
    }

    .c_each_preview_carrito_pedido {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
        border-bottom: 2px solid var(--primaryColor);
        width: 90%;
        margin: 0 auto;
    }

    .c_carrito_pedido_imagen {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 1 / 3;
    }

    .c_carrito_nombre_producto {
        color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_carrito_nombre_producto h6 {
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    .c_carrito_subtotal {
        color: var(--secondaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_informacion_totales {
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 4fr 1fr;
        font-size: 90%;
    }

    .c_total_pedido {
        width: 85%;
        margin: 0 auto;
        display: grid;
        font-size: 90%;
        grid-template-columns: 4fr 1fr;
    }

    .button_pagar_formulario_pedido {
        border: 2px solid var(--secondaryColor);
        padding: 5px 30px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .c_general_formualario_pedido_nuevo {
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_formulario_pedido_nuevo {
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 5fr 1px 3fr;
        min-height: 400px;
    }

    .c_general_preview_carrito_pedido_det {
        margin: 20px auto;
        max-width: 1200px;
        width: 100%;
        
    }

    .c_each_preview_carrito_pedido {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
        border-bottom: 2px solid var(--primaryColor);
        width: 90%;
        margin: 0 auto;
    }

    .c_carrito_pedido_imagen {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 1 / 3;
    }

    .c_carrito_nombre_producto {
        color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_carrito_nombre_producto h6 {
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    .c_carrito_subtotal {
        color: var(--secondaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 90%;
    }

    .c_informacion_totales {
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 4fr 1fr;
        font-size: 90%;
    }

    .c_total_pedido {
        width: 85%;
        margin: 0 auto;
        display: grid;
        font-size: 90%;
        grid-template-columns: 4fr 1fr;
    }

    .button_pagar_formulario_pedido {
        border: 2px solid var(--secondaryColor);
        padding: 5px 30px;
        border-radius: 30px;
        color: var(--secondaryColor);
        cursor: pointer;
    }
}
