.contenedorcMiniBoton {
    display: flex;
    height: max-content;
    width: max-content;
    font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    font-size: 16px;
    margin: auto;
    position: relative;
}

.cMiniBoton {
    border-radius: 100%;
    display: flex;
    background: var(--primaryColor);
    color: white;
    text-align: center;
    cursor: pointer;
    margin: auto;
    overflow: visible;
    height: auto;
    width: auto;
    padding: 0px;
    margin: 0px;
    transition: all .3s;
}

.cMiniBoton:hover, .cMiniBoton:focus, .cMiniBoton:active{
    /*box-shadow: 0px 0px 10px 2px var(--colorDisabled);*/
    transform: scale(1.2);
    transition: all .3s;
}