:root{
    --fuente: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    --colorLetraGeneral: white;
    --backgroundColor: white;
    --colorLetraFondo: rgb(49, 49, 49);
    --fondoMenu: #08172e;
    --primaryColor: #37628d;
    --secondaryColor: #00b213;
    --secondaryColor_Opacidad: #00b213a1;
    --mainColor_Opacidad: #2b4f73a1;
    --letraPrimaryColor: white; 
    --colorLetraSecondaryColor: white;
    /*--colorLetraMainColor_Opacidad: rgb(157, 157, 157);*/
    --colorLetraMainColor_Opacidad: #a1b4cc;
    --dark: #3a65af;
    --dark_Opacidad: #10141230;
    --colorLetraDark: white;
    --light: #dbf5f5;
    --light_Opacidad: #2a3a9f82;
    --colorLetraInput: black;
    --boxShadowMin: 0px 0px 5px 3px var(--letraPrimaryColor);
    --boxShadowMax: 0px 0px 5px 6px var(--letraPrimaryColor);
    --boxShadowActive: 0 0px 5px 4px var(--primaryColor);
    --fondoGradient: linear-gradient(237deg, var(--dark), var(--primaryColor));
    --colorError: #F44336;
    --colorErrorDark: #621B16;
    --colorErrorLight: #FEECEB;
    --colorInfo: #2196F3;
    --colorInfoDark: #0D3C61;
    --colorInfoLight: #E9F5FE;
    --colorWarning: #f8971d;
    --colorWarningDark: #663D00;
    --colorWarningLight: #FFF5E5;
    --colorSuccess: #4CAF50;
    --colorSuccessDark: #1E4620;
    --colorSuccessLight: #EDF7ED;
    --colorDisabled: #767e95;
    --colorTransparencia: #2b4f73a1;
    --colorTransparenciaSecundario: #00b21284;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}

/* #aux{
    position: sticky;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 50;
} */

#vectorArrowL{
    width: 20px;
    height: 20px;
    margin: 0px 5px;
}

#vectorArrowL polygon{
    fill: var(--secondaryColor);
}

#vectorArrowR{
    width: 20px;
    height: 20px;
    margin: 0px 5px;
}

#vectorArrowR polygon{
    fill: var(--secondaryColor);
}

#vectorSaveIcon{
    width: 20px;
    height: 20px;
    margin: 0 5px;
}

#vectorSaveIcon path{
    stroke: var(--secondaryColor);
}

#vectorSaveIcon rect{
    stroke: var(--secondaryColor);
}

#vectorSaveIcon line{
    stroke: var(--secondaryColor);
}

#vectorMenuIcon{
    width: 30px;
    height: 30px;
}

#vectorMenuIcon path{
    stroke: var(--primaryColor);
}

input {
    outline: none !important;
    /*height: 26.4px !important;*/
    /*height: 30px !important;*/
}

body{
    /* overflow: auto; */
    scrollbar-width: thin;
    scrollbar-color: var(--primaryColor) transparent;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background: #000;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}

body,
html {
    margin: unset;
    /* height: 100%; */
    width: 100%;
    color: var(--primaryColor);
}
html {
    overflow: hidden;
}

.disabled{
    background: white !important;
    border-color: var(--colorDisabled) !important;
    color: var(--colorDisabled) !important;
    pointer-events: none !important;
}
.oculto {
    display: none;
}

.d1 {
    width: 100%!important;
}

.d2 {
    width: 50%!important;
}

.d3 {
    width: 33.3%!important;
}

.d4 {
    width: 25% !important;
}

.d5 {
    width: 20%!important;
}

.d6 {
    width: 16.6%!important;
}

.d7 {
    width: 14.28%!important;
}

.d8 {
    width: 2.5%!important;
}

.d9 {
    width: 11.1%!important;
}

.d10 {
    width: 10%!important;
}


/* cyrillic-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZJhiI2B.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


/* cyrillic */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZthiI2B.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


/* greek-ext */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZNhiI2B.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}


/* greek */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZxhiI2B.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}


/* vietnamese */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZBhiI2B.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}


/* latin-ext */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZFhiI2B.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}