.cIniciaSesion .tituloModalForm {
    margin-bottom: 35px;
}  
.contenedorPrincipal {
    width: 100%;
    background-color:#E9EAE4  ;
    height: 500px;
    padding:15px;
}

.divPrincipal {      
    background-color: rgba(0, 0, 0, 0.5);  
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%; 
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    font-size: 16px;
    margin: auto;
    z-index: 5;

}
.modal{
    height: max-content;
    max-height: 95vh;
    width: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    display: none;
}
.contenedorAlerta {      
    background-color: white;
    border-radius: 15px;
    padding: 10px;    
    margin: auto;
    max-width: 450px;
    min-width: 300px;
    width: 50%;
    height: auto;
    overflow-y: hidden;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
   
}
.imagen{
    width: 100%;
    height: auto;
    text-align: center;   
    overflow-y: hidden; 
}
.titulo {
    color: var(--secondaryColor);
    font-size:16px;
    padding: 15px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bold;
    overflow-y: hidden;
}
.descripcion {
    color: #072480 ;
    font-size:13px;
    padding: 25px;
    margin: auto;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;  
    
}
.nota{
    font-size:12px;
    text-align: center;
    font-style: italic;
    color: #072480 ;
}
.botones {
    padding: 16px;
}
@media (max-width: 600px) {
    .divPrincipal {
       overflow: hidden;
    }
    .contenedorAlerta{
        overflow-y: auto;
        overflow-x: hidden;
    }
}
@media (max-width: 300px) {
    .divPrincipal {
       overflow: hidden;
    }
    .contenedorAlerta{
       width: 98%;
    }
}










