.contenedorcBoton {
    display: flex;
    height: max-content;
    width: max-content;
    font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    margin: auto;
}

.cBoton {
    border-radius: 100px;
    display: flex;
    background: var(--primaryColor);
    color: var(--letraPrimaryColor);
    height: auto;
    width: auto;
    align-items: middle;
    text-align: center;
    padding: 6px;
    /*padding-left: 12px;
    padding-right: 12px;*/
    cursor: pointer;
    min-width: 100px;
    margin: auto;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    margin-right: 5px;
    margin-left: 5px;
    transition-property: all;
    transition-duration: .2s;
}

.cBoton:hover, .cBoton:focus {
    box-shadow: var(--boxShadowMin);
}
.cBoton:active{
    box-shadow: var(--boxShadowActive);
}
.titulocBoton {
    align-self: center;
    margin: auto;
}

@media (max-width: 450px) {
    .cBoton {
        font-size: 12px;
        padding: 6px;
        margin: auto;
        margin-top: 1.5px;
        margin-bottom: 1.5px;
        min-width: 80px;
    }
    .contenedorcBoton {
        width: 100% !important;
        align-items: center;
        text-align: center;
    }
}