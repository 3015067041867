.contenedorGeneralDetallesProducto{
    max-width: 1920px;
    width: 100%;
    min-height: 600px;
    margin: 0 auto;
    height: auto;
}

.contenedorInternoDetallesProducto{
    max-width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedorCarouselDetallesProducto{
    width: 50%;
    height: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    flex-direction: column;
}

.contenedorCarouselDetallesProducto .carousel-root{
    width: 80%;
}

.contenedorDescripcionDetallesProducto{
    width: 50%;
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.descripcionDetalleProducto{
    margin-top: 8%;
    font-size: 16px;
}

.descripcionDetalleProducto h3{
    font-weight: 600;
    color: var(--primaryColor)   
}

.codigoDetalleProducto h5{
    font-size: 16px;
    color: var(--primaryColor);
}

.precioDetalleProducto{
    font-size: 35px;
    font-weight: 800;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--primaryColor)
}

.buttonDetalleProducto {
    width: 30%;
    padding: 5px 15px;
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0px;
}

.buttonDetalleProducto:hover{
    filter: brightness(110%);
}

.descripcionLargaDetalleProducto{
    width: 90%;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    color: var(--primaryColor);
}

.arrowDownDescripcionDetalleProduto{
    position: absolute;
    right: 10%;
    width: 10px;
}

.arrowUpDetalleProducto{
    position: absolute;
    right: 10%;
    width: 10px;
    transform: rotate(180deg);
    animation: rotateArrowDescripcion 0.2s ease-in;
}


.descripcionLargaDetalleProducto h5{
    border-top: 3px solid var(--primaryColor);
    border-bottom: 3px solid var(--primaryColor);
}

.imagesCarouselDetalleProducto{
    border: 2px solid var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

}

.imagesCarouselDetalleProducto img{
    width: 80%;
    height: auto;
    padding: 10%;
    max-height: 300px;
    object-fit: contain;
}

.leyenda_imgproducto{
    font-style: italic;
    color: gray;
    margin-bottom: 0;
}


.descripcionLargaDetalleProductoHidden{
    animation: goDown .2s ease-in;
}

.contenedorAccionesDetalleProducto{
    width: 100%;
    height: auto;
    min-height: 50px;
}

.controlesDetalleProducto{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 50px;
    font-size: 25px;
}

.buttonLessDetalleProducto{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    border: 1px solid var(--primaryColor);
    cursor: pointer;
}

.buttonLessDetalleProducto:hover{
    filter: brightness(110%);
    background-color: var(--primaryColor);
}

.indicadorCantidadDetalleProducto{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}

.indicadorCantidadDetalleProducto input{
    width: 100%;
    height: auto;
    min-height: 50px;
    direction: rtl;
    border: none;
    border: 1px solid var(--primaryColor);
    font-size: 30px;
}

.indicadorCantidadDetalleProducto input::-webkit-outer-spin-button,
.indicadorCantidadDetalleProducto input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buttonMoreDetalleProducto{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    border: 1px solid var(--primaryColor);
    cursor: pointer;
}

.buttonMoreDetalleProducto:hover{
    background-color: var(--primaryColor);
    filter: brightness(110%);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}




/*////////////////ANIMACIONES/////////////////////*/

@keyframes goDown {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }
    70% {
        transform: translateY(-5);
        opacity: 50%;
    }
    100% {
        transform: translateY(0);
    }
}

 @keyframes rotateArrowDescripcion {
     0% {
         transform:  rotate(0deg);
     }
     100%{
         transform: rotate(180deg);
     }
 }


/*////////////////ANIMACIONES/////////////////////*/



/*//////////////////////////////MEDIAS/////////////////////////////////*/

@media screen and (min-width:300px) and (max-width: 480px) {

    .contenedorGeneralDetallesProducto{
        max-width: 480px;
        width: 100%;
        min-height: 450px;
        margin: 0 auto;
        height: auto;
    }
    
    .contenedorInternoDetallesProducto{
        max-width: 480px;
        min-height: 0;
        margin: 20px auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .contenedorCarouselDetallesProducto{
        min-height: 0;
        padding: 0%;
        width: 100%;
    }

    .contenedorCarouselDetallesProducto .carousel-root{
        width: 80%;
    }

    .contenedorCarouselDetallesProducto .carousel-root .carousel .thumbs-wrapper{
        display: none;
    }
    .contenedorDescripcionDetallesProducto{
        min-height: 0;
        width: 80%;
        display: flex;
        align-items: center;
    }

    .descripcionDetalleProducto{
        margin-top: 0;
        font-size: 3.5vw;
    }

    .contenedorAccionesDetalleProducto{
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .controlesDetalleProducto{
        min-height: 30px;
    }

    .buttonLessDetalleProducto{
        min-height: 30px;
    }

    .indicadorCantidadDetalleProducto{
        min-height: 30px;
    }

    .indicadorCantidadDetalleProducto input{
        min-height: 30px;
        font-size: 16px;
    }

    .buttonMoreDetalleProducto{
        min-height: 30px;
    }

    .buttonDetalleProducto{
        width: 50%;
        font-size: 3vw;
    }
}

@media screen and (min-width:481px) and (max-width: 768px) {
    .contenedorGeneralDetallesProducto{
        max-width: 768px;
        width: 100%;
        min-height: 450px;
        margin: 0 auto;
        height: auto;
    }
    
    .contenedorInternoDetallesProducto{
        max-width: 768px;
        min-height: 0;
        margin: 20px auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .contenedorCarouselDetallesProducto{
        min-height: 0;
        padding: 0%;
        width: 100%;
    }

    .contenedorCarouselDetallesProducto .carousel-root{
        width: 80%;
    }

    .contenedorCarouselDetallesProducto .carousel-root .carousel .thumbs-wrapper{
        display: none;
    }
    .contenedorDescripcionDetallesProducto{
        min-height: 0;
        width: 80%;
        display: flex;
        align-items: center;
    }

    .descripcionDetalleProducto{
        margin-top: 0;
    }

    .contenedorAccionesDetalleProducto{
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .controlesDetalleProducto{
        min-height: 30px;
    }

    .buttonLessDetalleProducto{
        min-height: 30px;
    }

    .indicadorCantidadDetalleProducto{
        min-height: 30px;
    }

    .indicadorCantidadDetalleProducto input{
        min-height: 30px;
        font-size: 16px;
    }

    .buttonMoreDetalleProducto{
        min-height: 30px;
    }

    .buttonDetalleProducto{
        width: 35%;
        font-size: 2vw;
    }
}

@media screen and (min-width:769px) and (max-width: 1024px) {
    .contenedorGeneralDetallesProducto{
        max-width: 1024px;
        width: 100%;
        min-height: 450px;
        margin: 0 auto;
        height: auto;
    }
    
    .contenedorInternoDetallesProducto{
        max-width: 768px;
        min-height: 450px;
        margin: 0 auto;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .descripcionDetalleProducto{
    margin-top: 0%;
    }

    .contenedorCarouselDetallesProducto{
        min-height: 400px;
    }

    .contenedorDescripcionDetallesProducto{
        min-height: 400px;
    }

    .buttonDetalleProducto{
        width: 40%;
        font-size: 12px;
    }

    .contenedorAccionesDetalleProducto{
        min-height: 30px;
    }

    .controlesDetalleProducto{
        min-height: 30px;
    }

    .buttonLessDetalleProducto{
        min-height: 30px;
    }

    .indicadorCantidadDetalleProducto{
        min-height: 30px;
    }

    .indicadorCantidadDetalleProducto input{
        min-height: 30px;
        font-size: 2vw;
    }

    .buttonMoreDetalleProducto{
        min-height: 30px;
    }

}

@media screen and (min-width:1025px) and (max-width: 1200px) {

    .contenedorGeneralDetallesProducto{
        max-width: 1200px;
        width: 100%;
        min-height: 600px;
        margin: 0 auto;
        height: auto;
    }
    
    .contenedorInternoDetallesProducto{
        max-width: 1024px;
        min-height: 600px;
        margin: 0 auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonDetalleProducto{
        font-size: 1vw;
    }


}

/*//////////////////////////////MEDIAS/////////////////////////////////*/