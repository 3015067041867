.contenedorGeneralAlertSoftwone{
    position: fixed;
    height: auto;
    width: 100%;
    top: 10%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.contenedorInternoAlertSoftwone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-Azul);
    border-radius: 20px;
    width: 300px;
    height: auto;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.contenedorInternoAlertSoftwone h1{
    color: var(--color-Verde);
    font-size: 12px;
    width: 80%;
    text-align: center;
    font-weight: 200;
    margin-top: 20px;
    margin-bottom: 10px;
}

.contenedorInternoAlertSoftwone h2{
    width: 80%;
    color: white;
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 10px;
    text-align: justify;
    text-align-last: center;
    word-break: break-word;
}

.buttonAlertSoftwone{
    width: auto;
    padding: 0px 10px;
    margin-bottom: 10px;
    background-color: transparent;
    border: 2px solid var(--color-Verde);
    border-radius: 20px;
    color: var(--color-Verde);
    font-size: 12px;
    font-weight: 200;
    cursor: pointer;
}

.buttonAlertSoftwone:hover{
    color: white;

}

    @-webkit-keyframes slideInDown {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    }
    @keyframes slideInDown {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    } 

