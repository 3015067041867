.cContenedorColores {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cListaColores {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.cDivColor {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: max-content;
  width: 150px;
}

.cEtiquetaColor {
  display: flex;
  text-align: center;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 40px;
}

.cTitloEtiquetaColor {
  display: flex;
  margin: auto;
  margin-bottom: 0px;
}

.cContendorEjemploColor {
  display: flex;
  flex-direction: column;
}

.cDivEjemploColor {
  height: 30px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border: 1px solid rgba(0, 0, 0, 0.346);
}

.react-colorful {
  height: 150px !important;
  width: 150px !important;
}

.cEjemploFondo {
  width: 100%;
  min-height: 50px;
  box-shadow: 0px 0px 2px 2px rgb(147, 147, 147);
}

.cEjemploColores {
  padding: 1%;
}

.cEjemploFondo {
  border-radius: 10px;
  padding: 10px;
  overflow: visible;
}

.cEjemploMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 35px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 14px;
  cursor: pointer;
  min-width: 100px;
  width: 100%;
  border-left: 1px solid;
  border-right: 1px solid;
}

.cEjemplosEtiquetas {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

@media (max-width: 950px) {
  .cListaColores {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 750px) {
  .cListaColores {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 580px) {
  .cListaColores {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .cListaColores {
    grid-template-columns: repeat(1, 1fr);
  }
}