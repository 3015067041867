.c_interno_carrito{
    max-width: 1200px;
    width: 90%;
    height: auto;
    margin: 20px auto;
}

.c_titulo_carrito{
    font-size: 16px;
    margin: 20px auto;
    width: 100%;
    color: var(--secondaryColor);
}

.c_titulo_carrito h3{
    font-weight: 600;
}

.c_general_producto_carrito{
    display: grid;
    grid-template-columns: 50% 20% 30%;
    align-items: center;
    border-top: 2px solid var(--primaryColor);
    border-bottom: 2px solid var(--primaryColor);
}   

.c_interno_carrito > .c_general_producto_carrito ~ .c_general_producto_carrito {
    border-top: none;
}

.c_img_caracteristicas_producto_carrito{
    display: grid;
    grid-template-columns: 30% 50% 10%;
    align-items: center;
}

.c_img_producto_carrito{
    width: 100%;
    height: 100%;
}

.c_img_producto_carrito img{
    width: 70%;
    height: auto;
}

.c_caracteristicas_producto_carrito{
    margin: 0 auto;
    text-align: justify;
    font-size: 90%;
    width: 100%;
}

.c_caracteristicas_producto_carrito h6{
    color: var(--primaryColor);
    font-weight: 600;
}

.eliminar_producto_carrito {
    border: 2px solid var(--primaryColor);
    text-align: center;
    width: 25px;
    height: 25px;
    margin: none;
    border-radius: 50%;
    cursor: pointer;
    margin-block-start: none !important;
    margin-block-end: none !important;
}

.eliminar_producto_carrito:hover{
        background-color: var(--primaryColor);
        color: var(--letraPrimaryColor);
}

.c_botones_cantidad_carrito{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.button_less_detalleProducto{
    width: 20%;
    height: 100%;
    text-align: center;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button_less_detalleProducto:hover{
    background-color: var(--primaryColor);
        color: var(--letraPrimaryColor);
}

.indicador_cantidad_detalle_producto{
    width: 20%;
}

.indicador_cantidad_detalle_producto input{
    width: 100%;
    height: 30px;
    text-align: center;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
}

.button_more_detalle_producto{
    width: 20%;
    height: 100%;
    text-align: center;
    border: 1px solid var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primaryColor);
    cursor: pointer;
}

.button_more_detalle_producto:hover{
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
}

.c_costo_producto{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondaryColor)
}

.c_general_acciones_carrito{
    max-width: 1920px;
    width: 100%;
    height: auto;
    margin: 20px auto;
}

.c_interno_acciones_carrito{
    max-width: 1200px;
    width: 90%;
    height: auto;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c_button_regresar{
    border: 1px solid var(--secondaryColor);
    padding: 5px 10px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.c_button_regresar h6 {
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.c_button_regresar:hover{
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
    
}

.c_button_regresar:hover #vectorArrowL polygon{
    fill: var(--colorLetraSecondaryColor);
}


.c_button_continuar{
    border: 1px solid var(--secondaryColor);
    border-radius: 30px;
    padding: 5px 10px;
    color: var(--secondaryColor);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.c_button_continuar h6{
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.c_button_continuar:hover{
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.c_button_continuar:hover #vectorArrowR polygon{
    fill: var(--colorLetraSecondaryColor);
}

.c_canasta_vacia{
    width: 100%;
    max-width: 1200px;
    min-height: 200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.c_canasta_vacia_button_regresar{
    border: 1px solid var(--secondaryColor);
    padding: 5px 10px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.c_canasta_vacia_button_regresar h6{
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.c_canasta_vacia_button_regresar:hover{
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.c_canasta_vacia_button_regresar:hover #vectorArrowL polygon{
    fill: var(--colorLetraSecondaryColor);
}


@media screen and (min-width:320px) and (max-width: 480px) {

    .c_general_carrito {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_carrito {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_general_producto_carrito {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        border-top: 2px solid var(--primaryColor);
        border-bottom: 2px solid var(--primaryColor);
    }

    .c_img_caracteristicas_producto_carrito {
        display: grid;
        grid-template-columns: 30% 50% 10%;
        align-items: center;
        justify-content: space-around;
    }

    .c_caracteristicas_producto_carrito {
        /* border: 1px solid black; */
        margin: 0 auto;
        text-align: justify;
        font-size: 90%;
        width: 100%;
    }

    .eliminar_producto_carrito {
        border: 2px solid var(--primaryColor);
        text-align: center;
        width: 25px;
        height: 25px;
        margin: none;
        border-radius: 50%;
        cursor: pointer;
        margin-block-start: none !important;
        margin-block-end: none !important;
    }

    .c_interno_acciones_carrito {
        max-width: 1200px;
        width: 90%;
        height: auto;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

@media screen and (min-width:481px) and (max-width: 768px) {

    .c_general_carrito {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_carrito {
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_general_producto_carrito {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        border-top: 2px solid var(--primaryColor);
        border-bottom: 2px solid var(--primaryColor);
    }

    .c_img_caracteristicas_producto_carrito {
        display: grid;
        grid-template-columns: 30% 50% 10%;
        align-items: center;
        justify-content: space-around;
    }

    .c_caracteristicas_producto_carrito {
        /* border: 1px solid black; */
        margin: 0 auto;
        text-align: justify;
        font-size: 90%;
        width: 100%;
    }

    .eliminar_producto_carrito {
        border: 2px solid var(--primaryColor);
        text-align: center;
        width: 25px;
        height: 25px;
        margin: none;
        border-radius: 50%;
        cursor: pointer;
        margin-block-start: none !important;
        margin-block-end: none !important;
    }

    .c_interno_acciones_carrito {
        max-width: 480px;
        width: 90%;
        height: auto;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

@media screen and (min-width:769px) and (max-width: 1024px) {
    .c_general_carrito {
        max-width: 1024px;
        width: 90%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_carrito {
        max-width: 1024px;;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_caracteristicas_producto_carrito {
        /* border: 1px solid black; */
        margin: 0 auto;
        text-align: justify;
        font-size: 90%;
        width: 100%;
    }

    .c_interno_acciones_carrito {
        max-width: 1200px;
        width: 90%;
        height: auto;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media screen and (min-width:1025px) and (max-width: 1200px) {


    .c_general_carrito {
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

    .c_interno_carrito {
        max-width: 1200px;
        width: 90%;
        height: auto;
        margin: 20px auto;
    }

    .c_caracteristicas_producto_carrito {
        /* border: 1px solid black; */
        margin: 0 auto;
        text-align: justify;
        font-size: 90%;
        width: 100%;
    }

    .c_interno_acciones_carrito {
        max-width: 1200px;
        width: 90%;
        height: auto;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}