

.cContenedorPrincipal {
  display: flex;
  flex-wrap: wrap;    
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  padding: 0.8% 4% 1% 4%;
}


.cPanelDatosDomicilio {
  width: 70%;
  padding: 0px;
  color: var(--secondaryColor);
  border-right: 2px solid var(--primaryColor);
  vertical-align: center;
  line-height: 42px;
/*   @media (max-width: 500px): {
    width: 32%;
    margin-left: 0%;
  } */
}

.cPanelProductos {
  width: 30%;
  padding: 0px;
  color: var(--secondaryColor);
/*   border-right: 2px solid var(--primaryColor); */
  vertical-align: center;
  line-height: 42px;
/*   @media (max-width: 500px): {
    width: 32%;
    margin-left: 0%;
  } */
}

.cPanelTitle {
  padding: 1.2% 0% 1.5% 0%;
  color: var(--secondaryColor);
  font-size: 25px;
  font-weight: 600;
}

.cPanelCamposText {
  padding: 0% 0% 2% 0%;
  display: flex;
  flex-direction: row;  
}


.cTextCorreo {
  width: 60%;
  height: 50px;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cTextTelefono {  
  width: 30%;
  height: 50px;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cTextEnviarDomicilio {  
  width: 45%;
  height: 50px;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cTextRecogerTienda {  
  width: 45%;
  height: 50px;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cTextMedios {  
  width: 45%;
  height: 50px;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cTextAbajo {  
  width: 28.4%;
  height: 50px;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cTextComodin {  
  width: 5%;
  height: 50px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

.cBotonesComodin {  
  width: 38%;
  height: 50px;
  min-width: unset;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}


.cBotonRegresar {  
  width: 28.4%;
  height: 50px;
  min-width: unset;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: var(--secondaryColor);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}


.cBotonContinue {  
  width: 28.4%;
  height: 50px;
  min-width: unset;
  border: 2px solid var(--secondaryColor);
  border-radius: 20px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: var(--colorLetraGeneral);
  background-color: var(--secondaryColor);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
} 
.cBotonContinue:hover {
  color: var(--secondaryColor);
  background-color: var(--backgroundColor);
}












.cPanelProducto {
  display: flex; 
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--primaryColor);
}

.cPanelImportes {
  display: flex; 
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--primaryColor);
}

.cPanelTotales {
  display: flex; 
  margin-bottom: 0px;
  width: 100%;
  height: auto;
}


.cImportesProducto {  
  width: calc(100% - 25%);
  margin-left: 5%;
  height: auto;
  align-items: right;/* 
  @media (max-width: 400px): {
    width: 100%;
    margin-left:0%;
  }      */
}

.cDescription {
  text-align: left;
  font-size: 7.5px;
  color: var(--primaryColor);
  font-weight: 400;  
  width: 70%;
}


.cTotalProducto {
  width: 30%;
  text-align: right;
  padding: 0px;
  float: right;
  color: var(--secondaryColor);
  vertical-align: center;
  height: 42px;
  line-height: 42px;
  font-weight: 400;
}


.cContenedorImportes {  
  width: 100%;
  float: right;
  vertical-align: center;
} 



.cPanelPrincipalProducto {
  padding: 0% 0% 0% 6%;
  display: flex; 
  flex-direction: column;  
}


.cPanelProductoTotal {
  display: flex; 
  margin-bottom: 0px;
  width: 100%;
  height: auto;
}

.cPanelProductoOtros {
  display: flex; 
  flex-wrap: wrap;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--primaryColor);
}

.cProductoTotalText {  
  width: 45%;
  height: 50px;
  min-width: unset;
  color: var(--primaryColor);
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}


.cProductoTotalSumaText {  
  width: 45%;
  height: 50px;
  text-align: right;
  min-width: unset;
  font-weight: 700;  
/*   @media (max-width: 750px): {
      display: none
   };
   @media (min-width: 1200px): {
      display: unset
 } */
  
}

