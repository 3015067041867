.cTablaPedidoDetallado{
    max-width: 450px;
    width: 100%;
}

.cTablaPedidoDetallado table{
    width: 100%;
    border: 1px solid black;
    vertical-align: middle;
    text-align: center;
    font-size: 10px;
    border-collapse: collapse;
}

.cTablaPedidoDetallado table th{
    background-color: rgb(144, 144, 144);
    border: 1px solid black;
    padding: 2px;
}

.cTablaPedidoDetallado table th:nth-child(1){
    width: 15%;
}

.cTablaPedidoDetallado table th:nth-child(3){
    width: 15%;
}

.cTablaPedidoDetallado table tr:nth-child(even){
    background-color: rgb(222, 222, 222);
    border: 1px solid black;
}

.cTablaPedidoDetallado table td{
    padding: 2px;
}