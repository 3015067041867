#precio{
    background-color: var(--primaryColor);
    color:white;
    padding: 5px 10px;
    border-radius: 10px;
    position:  absolute;
    bottom: 0;
    margin: 2px;
    right: 0;
    z-index: 1;
    user-select: none;
}

#contenImg {
    position: relative;
}