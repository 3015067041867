.c_general_menu_responsive{
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: var(--colorTransparencia);
    z-index: 2;
}

.c_interno_menu_responsive{
    width: 30%;
    min-width: 320px;
    height: 100%;
    overflow-y: scroll;
    background-color: var(--primaryColor);
}

.c_menu_items_responsive{
    padding-top: 30px;
}

.c_each_ruta_menu_responsive{
    width: 100%;
    color: var(--letraPrimaryColor);
    border: 1px solid white;
    padding: 10px 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
}

.i_each_ruta_titulo{
    font-size: 130%;
    text-align: left;
    width: 50%;
    padding-left: 5px;
}

.iconoMenu_responsive{
    width: 10%;
    height: auto;

}

.c_menu_productos_responsive{
    width: 100%;
    border-top: none;
}

.i_menu_productos_grupos_responsive{
    width: 100%;
    color: var(--letraPrimaryColor);
    padding: 10px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    border-top: 1px solid var(--secondaryColor);
    border-bottom: 1px solid var(--secondaryColor);
    font-weight: 800;
    position: relative;
    font-size: 90%;
}

.i_menu_productos_grupos_responsive::before{
    content: '\203A';
    position: absolute;
    position: absolute;
    left: 2%;
    transform: translate(-50%, -50%);
    font-size: 90%;
}

.c_menu_productos_subgrupos_responsive{
    width: 100%;
}

.i_menu_productos_subgrupos_responsive{
    font-size: 65%;
    width: 100%;
    color: var(--letraPrimaryColor);
    padding: 10px 30px;
    height: auto;
    font-weight: 600;
    border-top: 1px solid var(--secondaryColor);
    border-bottom: 1px solid var(--secondaryColor);
    position: relative;
}

.i_menu_productos_subgrupos_responsive::after{
    content: '\2192';
    position: absolute;
    position: absolute;
    bottom: 0;
    left: 90%;
    transform: translate(-50%, -50%);
    font-size: 25px;
}