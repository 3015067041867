.contenedorDescripcionEmpresa {
    width: 95%;
    margin: auto;
    height: auto;
    min-height: 250px;
    display: flex;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    background: transparent;
    box-shadow: 0px 0px 10px 5px var(--primaryColor);
}

.ImagenRepresentativaDescripcionEmpresa {
    width: 100%;
    margin: unset;
    height: auto;
    max-height: 350px;
    display: flex;
    position: relative;
}

.ImagenRepresentativaDescripcionEmpresa.Reverse {
    flex-direction: row-reverse;
}

.imgRepresentativaDescripcionEmpresa {
    height: 100%;
    width: 50%;
    object-fit: cover;
    overflow: auto;
}

.TituloDescripcion {
    display: table-row;
    width: 100%;
    color: var(--letraPrimaryColor);
    text-align: center;
    background: var(--primaryColor);
    font-weight: bold;
    font-size: 18px;
    height: 40px;
}

.TituloDescripcionAux {
    margin: auto;
    width: 80%;
    font-size: 20px;
    display: table-cell;
    vertical-align: middle;
}

.ContenedorTextoDescripcionEmpresa {
    width: 50%;
    color: black;
    font-size: 16px;
    overflow: auto;
    position: relative;
    display: table;
}

.TexoImagenRepresentativaDescripcionEmpresa {
    margin: auto;
    width: 80%;
    font-size: 16px;
    display: table-cell;
    vertical-align: middle;
    padding: 10px;
    background: rgba(255, 255, 255, 0.762);
}

.categorias {
    width: 95%;
    margin: auto;
    overflow: visible;
    scrollbar-width: thin;
    scrollbar-color: var(--primaryColor) transparent;
    display: inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-top: 20px;
    margin-bottom: 25px;
    
}

.grupos {
    display: flex;
    margin: auto;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.grupos a{
    width: 100%;
    height: 100%;
}

.contenedorGrupo {
    width: 100%;
    height: 100%;
    transition-duration: .3s;
}

.contenedorGrupo:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    transition-property: transform;
    transition-duration: .3s;
}

.imagenGrupo {
    margin: 0 auto;
    background: transparent;
    width: 100%;
}

.formatoImagen {
    background: transparent;
    height: 120px;
    width: 100%;
    object-fit: contain;
    padding: 5px;
    
}

.tituloGrupo {
    text-align: center;
    font-size: 12px;
    color: var(--primaryColor);
    font-weight: bold;
    display: block;
}

.tituloGrupos {
    width: 60%;
    font-size: 18px;
    font-weight: bold;
    margin: 15px auto auto;
    text-align: center;
    color: var(--primaryColor);
    letter-spacing: 3px !important;
    padding-block: 10px;
    border-bottom: solid 2px var(--primaryColor);
}

.cContenedorLanding {
    display: flex;
    flex-direction: column;
    background: var(--backgroundColor);
}

.cSeccionA {
    display: flex;
    background: var(--primaryColor);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cSeccionAPrincipal {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 240px;
    margin: auto;
    overflow: hidden;
}

.cSeccionAFondo {
    display: flex;
}

.cImgAFondo {
    height: auto;
    width: 100%;
    display: flex;
}

.cSeccionAParrafo {
    display: flex;
    flex-direction: column;
    width: max-content;
    right: 4%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    color: var(--primaryColor);
    font-weight: bold;
}

.cSeccionALinea1 {
    font-size: 22px;
    width: max-content;
    margin: auto;
    padding-top: 4px;
    padding-bottom: 4px;
}

.cSeccionALinea2 {
    font-size: 27px;
    width: max-content;
    justify-content: space-between;
    padding-top: 4px;
    padding-bottom: 4px;
    margin: auto;
    color: var(--letraPrimaryColor);
}

.cSeccionALinea3 {
    font-size: 30px;
    width: max-content;
    margin: auto;
    padding-top: 4px;
    padding-bottom: 4px;
}

.cSeccionAEnvios {
    color: var(--letraPrimaryColor);
    margin: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    letter-spacing: 2px !important;
    text-align: center;
}

.cSeccionB {
    display: flex;
    background: var(--primaryColor);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cSeccionBPrincipal {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 350px;
    margin: auto;
    overflow: hidden;
}

.cSeccionBFondo {
    display: flex;
}

.cImgBFondo {
    height: auto;
    width: 100%;
    display: flex;
}

.cSeccionBParrafo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 35%;
    color: var(--primaryColor);
    font-weight: bold;
}

.cSeccionBLinea1 {
    font-size: 22px;
    width: max-content;
    height: auto;
    margin: auto;
    margin-bottom: 0px;
    margin-top: 0px;
    color: var(--secondaryColor);
}

.cSeccionBLinea2 {
    font-size: 26px;
    width: max-content;
    justify-content: space-between;
    margin: auto;
    margin-top: 20px;
    color: var(--letraPrimaryColor);
}

/* @media (max-width: 1700px) {
    .categorias {
        width: 70%;
    }

    .cSeccionAParrafo {
        right: 7%;
    }

    .cSeccionALinea1 {
        font-size: 25px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .cSeccionALinea2 {
        font-size: 31px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .cSeccionALinea3 {
        font-size: 35px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
} */

/* @media (max-width: 1400px) {
    .categorias {
        width: 85%;
    }

    .cSeccionAParrafo {
        right: 7%;
    }

    .cSeccionALinea1 {
        font-size: 23px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .cSeccionALinea2 {
        font-size: 28px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .cSeccionALinea3 {
        font-size: 32px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
} */

@media (max-width: 1200px) {
    .categorias {
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 920px) {
    .cSeccionAParrafo {
        right: 4%;
    }

    .cSeccionALinea1 {
        font-size: 22px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .cSeccionALinea2 {
        font-size: 27px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .cSeccionALinea3 {
        font-size: 30px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

@media (max-width: 768px) {

    .categorias{
        grid-template-columns: 1fr 1fr;
    }

    .cSeccionAParrafo {
        right: 4%;
    }

    .cSeccionALinea1 {
        font-size: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .cSeccionALinea2 {
        font-size: 25px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .cSeccionALinea3 {
        font-size: 27px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

@media (max-width: 700px) {

    .cSeccionAParrafo {
        right: 3%;
    }

    .cSeccionALinea1 {
        font-size: 18px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .cSeccionALinea2 {
        font-size: 23px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .cSeccionALinea3 {
        font-size: 25px;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

@media (max-width: 620px) {

    .cSeccionBLinea1 {
        font-size: 18px;
    }

    .cSeccionBLinea2 {
        font-size: 20px;
        margin-top: 4px;
    }

    .cSeccionBParrafo {
        top: 36%;
    }
}

@media (max-width: 565px) {
    .cSeccionAParrafo {
        right: 3%;
    }

    .cSeccionALinea1 {
        font-size: 17px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .cSeccionALinea2 {
        font-size: 21px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .cSeccionALinea3 {
        font-size: 23px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .cSeccionAEnvios {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .categorias {
        display: inline-grid;
    }


    .cSeccionAParrafo {
        padding: 5px;
        border-radius: 10px;
        right: 0%;
    }

    .cSeccionAEnvios {
        font-size: 12px;
    }

    .cSeccionBParrafo {
        top: 32%;
    }
}

@media (max-width: 400px) {
    .cSeccionAParrafo {
        padding: 5px;
        border-radius: 7px;
    }

    .cSeccionALinea1 {
        font-size: 15px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .cSeccionALinea2 {
        font-size: 18px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .cSeccionALinea3 {
        font-size: 19px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .cSeccionAEnvios {
        font-size: 12px;
        letter-spacing: 0px !important;
    }

    .cSeccionBParrafo {
        top: 28%;
    }
}

@media (max-width: 350px) {
    .cSeccionBParrafo {
        top: 25%;
    }

    .cSeccionBLinea1 {
        font-size: 14px;
    }

    .cSeccionBLinea2 {
        font-size: 15px;
        margin-top: 4px;
    }


    .cSeccionALinea1 {
        font-size: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .cSeccionALinea2 {
        font-size: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .cSeccionALinea3 {
        font-size: 16px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .cSeccionAEnvios {
        font-size: 10px;
        letter-spacing: 0px !important;
    }
}

@media (max-width: 300px) {
    .cSeccionBParrafo {
        top: 20%;
    }

    .cSeccionBLinea1 {
        font-size: 14px;
    }

    .cSeccionBLinea2 {
        font-size: 14px;
    }
}

@media (max-width: 750px) {
    .ImagenRepresentativaDescripcionEmpresa {
        flex-direction: column;
        height: auto;
        max-height: unset;
    }

    .ImagenRepresentativaDescripcionEmpresa.Reverse {
        flex-direction: column;
    }

    .TituloDescripcionAux {
        width: 100%
    }

    .ContenedorTextoDescripcionEmpresa {
        height: auto;
        width: 100%;
    }

    .TexoImagenRepresentativaDescripcionEmpresa {
        left: 47%;
        width: 85%;
        font-size: 14px;
    }

    .imgRepresentativaDescripcionEmpresa {
        height: auto;
        width: 100%;
        max-height: 250px;
    }

    .TituloDescripcionAux {
        font-size: 18px
    }
}