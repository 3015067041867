.cIniciaSesion .tituloModalForm {
    margin-bottom: 35px;
}  
.contenedorPrincipalRecupera{
    background-color:#E9EAE4  ;
    padding:15px;
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%; 
    margin:auto;
}
.contenedorRecupera {    
    width: 95%;
    max-width: 450px;
    height: auto;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    overflow: hidden;
    margin:auto;
}
.leyenda {
    color: var(--secondaryColor);
    font-size:16px;
    padding: 15px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bold;
}
.sigPaso {
    color: var(--colorInfoDark) !important;
    font-size:13px;
    padding: 20px;
    margin: auto;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: 600; 
}
@media (max-width: 600px) {
    .sigPaso {
        padding: 5px;
        font-size:12px;
    }
}
.formulario{
    padding: 15px; 
}
.cInputRecuperar {
    border-radius: 100px;
    color: var(--primaryColor);

    padding: 20px;
    height: 40px;
    border-color: var(--colorInfo) !important;   
}
.cInputRecuperar input::placeholder {
    opacity: 0.86;
    color: var(--colorInfoDark);
    font-weight: 550;   
}


.contenedorRecupera .contenedorcInput {
    margin-bottom: 15px;
}

.contenedorRecupera .iconoInput {
    position: absolute;
    top: 9px;
    left: 18px;
    border-radius: 100%;
    align-items: center;
    padding: 0px;
    height: 22px;
    width: 22px;
    display: flex;
    margin: auto;
    font-size: 22px;
    box-shadow: 0px 0px 0px 2px var(--letraPrimaryColor);
    overflow: visible;
}



.cContenedorBotonesLogin {
width: 100%;
text-align: center;
margin-bottom: 30px
}

.cContenedorBotonesLogin .cBoton {
min-width: 180px;
margin-top: 10px;
}

