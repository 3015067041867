.scContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
}

.imgCancel{
  max-height:400px;
}

.h4Cancel {
  color: #00B213;
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.pTextCancel {
  color: #284E73;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 400px) {
  .imgCancel{
    max-height:300px;
  }
}