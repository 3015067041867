.c_general_pago_exitoso{
    max-width: 1920px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #E6E7E9;
      z-index: 52;
      margin: 0 auto;
}

.c_interno_pago_exitoso{
    max-width: 1200px;
    width: 90%;
    height: auto;
    margin: 0 auto;
}

.c_interno_pago_exitoso{
    width: 50%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}

.c_texto_pago_exitoso{
    text-align: center;
}

.c_texto_pago_exitoso h6{
    color: var(--secondaryColor);
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
}

.c_texto_pago_exitoso h5{
    color: var(--primaryColor);
    font-size: 20px;
    font-weight: 600;
}

@media screen and (min-width:320px) and (max-width: 480px) {
    .c_general_pago_exitoso{
        max-width: 480px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #E6E7E9;
          z-index: 52;
    }
    
    .c_interno_pago_exitoso{
        max-width: 480px;
        width: 50%;
        height: auto;
    }

    .c_texto_pago_exitoso h6{
        color: var(--secondaryColor);
        font-size: 90%;
        font-weight: 600;
    }
    
    .c_texto_pago_exitoso h5{
        color: var(--primaryColor);
        font-size: 90%;
        font-weight: 600;
    }
}

@media screen and (min-width:481px) and (max-width: 768px) {
    .c_general_pago_exitoso{
        max-width: 768px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #E6E7E9;
          z-index: 52;
    }
    
    .c_interno_pago_exitoso{
        max-width: 769px;
        width: 50%;
        height: auto;
    }

    .c_texto_pago_exitoso h6{
        color: var(--secondaryColor);
        font-size: 90%;
        font-weight: 600;
    }
    
    .c_texto_pago_exitoso h5{
        color: var(--primaryColor);
        font-size: 90%;
        font-weight: 600;
    }
}

@media screen and (min-width:769px) and (max-width: 1024px) {

    .c_general_pago_exitoso{
        max-width: 1024px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #E6E7E9;
          z-index: 52;
    }
    
    .c_interno_pago_exitoso{
        max-width: 769px;
        width: 50%;
        height: auto;
    }

    .c_texto_pago_exitoso h6{
        color: var(--secondaryColor);
        font-size: 16px;
        font-weight: 600;
    }
    
    .c_texto_pago_exitoso h5{
        color: var(--primaryColor);
        font-size: 20px;
        font-weight: 600;
    }
    
}

@media screen and (min-width:1025px) and (max-width: 1200px) {

    .c_general_pago_exitoso{
        max-width: 1200px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #E6E7E9;
          z-index: 52;
    }
    
    .c_interno_pago_exitoso{
        max-width: 1025px;
        width: 50%;
        height: auto;
    }

    .c_texto_pago_exitoso h6{
        color: var(--secondaryColor);
        font-size: 16px;
        font-weight: 600;
    }
    
    .c_texto_pago_exitoso h5{
        color: var(--primaryColor);
        font-size: 20px;
        font-weight: 600;
    }
    
}