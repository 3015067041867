.contenedorGeneralAgregarTarjetas{
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.contenedorInternoAgregarTarjetas{
    max-width: 500px;
    max-height: 540px;
    min-height: 350px;
    width: 90%;
    height: 90%;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}

.tituloAgregarTarjetas h3{
    padding: 5% 0px;
    color: var(--primaryColor);
    font-weight: 200;
    text-transform: uppercase;
}

.formTarjetas{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 90%;
}

.contenedorNombreTarjeta{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: auto;
}

.contenedorNombreTarjeta label{
    color: #A8A8A8;
    font-weight: 600;
    font-family: Titulo2;
    padding-left: 15px;
    margin-bottom: 5px;
}

.contenedorNombreTarjeta input{
    color: var(--primaryColor);
    width: calc(100% - 15px);
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    outline: none;
    border: 2px solid var(--primaryColor);
    padding-left: 15px;
    color: black;
}

.contenedorNombreTarjeta input::placeholder{
    font-size: 90%;
}
 
.contenedorNumeroTarjeta{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: auto
}

.contenedorNumeroTarjeta label{
    color: #A8A8A8;
    font-weight: 600;
    font-family: Titulo2;
    padding-left: 15px;
    margin-bottom: 5px;
}

.contenedorNumeroTarjeta input{
    color: var(--primaryColor);
    width: calc(100% - 15px);
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    outline: none;
    border: 2px solid var(--primaryColor);
    padding-left: 15px;
    color: black;
}

.contenedorNumeroTarjeta input::placeholder {
    font-size: 90%; 
}

.agrupadorDatos{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}

.contenedorFechaTarjeta{
    width: 100%;
    height: auto;
}

.contenedorFechaTarjeta label{
    color: #A8A8A8;
    font-weight: 600;
    font-family: Titulo2;
    padding-left: 15px;
}

.agrupadorFechas{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.agrupadorMesTarjeta{
    width: 45%;
}

.agrupadorMesTarjeta select{
    width: 100%;
    height: 30px;
    border: 2px solid var(--primaryColor);
    border-radius: 10px;
}

.agrupadorYearTarjeta{
    width: 45%;
}

.agrupadorYearTarjeta select{
    width: 100%;
    height: 30px;
    border: 2px solid var(--primaryColor);
    border-radius: 10px;
}

.agrupadorCvv{
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.agrupadorCvv  label{
    color: #A8A8A8;
    font-weight: 600;
    font-family: Titulo2;
    padding-left: 15px;
}

.agrupadorCvv input{
    color: var(--primaryColor);
    width: calc(100% - 15px);
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    outline: none;
    border: 2px solid var(--primaryColor);
    padding-left: 15px;
    color: black;
}

.agrupadorCvv input::placeholder {
    font-size: 100%;
}


.buttonSubmitAddTarjeta{
    width: 200px;
    background-color: var(--primaryColor);
    color: white;
    border: none;
    padding: 10px 0;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
}

.buttonSubmitAddTarjeta:hover{
    background-color: var(--secondaryColor);
}

@media screen and (min-width:320px) and (max-width: 480px) {

}

@media screen and (min-width:481px) and (max-width: 768px) {


}

@media screen and (min-width:769px) and (max-width: 1024px) {

   
}

@media screen and (min-width:1025px) and (max-width: 1200px) {
    
}