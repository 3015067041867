.cModal {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  font-size: 16px;
  margin: auto;
  z-index: 5;
}

.cModalAux {
  display: flex;
  height: auto;
  max-height: 100%;
  width: 100%;
  margin: auto;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.cModalChico {
  width: 70%;
  max-width: 800px;
}

.cModalForm {
  height: max-content;
  max-height: 95vh;
  width: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--primaryColor);
  flex-direction: column;
  margin: auto;
  font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  border-radius: 18px;
}

.contenedorTituloModalForm {
  margin: auto;
  margin-top: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 2;
  font-size: 24px;
  color: var(--letraPrimaryColor);
  background-color: var(--primaryColor);
}

.contenedorIconoModalForm {
  display: flex;
  height: max-content;
  width: max-content;
  margin: auto;
  margin-top: 7px;
  margin-right: 7px;
}

.contenedorIconoModalFormAux {
  display: flex;
  margin: auto;
}

.imagenOferta
{
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border-radius: 2rem;
}

.contenedorIconoModalForm:hover,
.contenedorIconoModalForm:focus,
.contenedorIconoModalForm:active {
  cursor: pointer;
  box-shadow: 0px 0px 0px 2px white;
  border-radius: 15px;
}

.tituloModalForm {
  text-transform: capitalize;
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: bold;
}

@media (max-width: 550px) {
  .cModalForm {
    width: 100%;
  }
  .cModalChico {
    width: 90%;
  }
}

@media (min-width: 900px) {
  .cModalForm {
    width: 75%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}