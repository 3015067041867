.c_general_loader_pago {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(48deg, #ffb8b8a3, #cfe070aa);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;
    z-index: 50;
  }
  
  .c_interno_loader_pago {
    width: 150px;
    height: 150px;
    position: relative;
    z-index: 51;
  }
  
  .c_interno_loader_pago svg {
      width: 90%;
      fill: none;
     } 
  
  .load-one {
      transform-origin: 50% 50%;
      stroke-dasharray: 0.7 0.3;
      stroke-linecap: round;
      stroke-width: 3px;
      stroke: var(--primaryColor);
      animation: load 1.5s infinite ease-in;
      animation-direction: reverse;
  }
  
  .load-two {
      transform-origin: 50% 50%;
      stroke-dasharray: 0.7 0.3;
      stroke-linecap: round;
      stroke-width: 3px;
      stroke: var(--secondaryColor);
      fill: var(--primaryColor);
  }
  
  .load-three {
      transform-origin: 50% 50%;
      stroke-dasharray: 0.7 0.3;
      stroke-linecap: round;
      stroke-width: 3px;
      stroke: var(--secondaryColor);
      animation: load 1.5s infinite;
  }
  
  
  @keyframes load {
    100% {
      transform: rotate(360deg);
    }
  }
  

@-webkit-keyframes AnimationName {
    0%{background-position:0% 7%}
    50%{background-position:100% 94%}
    100%{background-position:0% 7%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 7%}
    50%{background-position:100% 94%}
    100%{background-position:0% 7%}
}
@keyframes AnimationName {
    0%{background-position:0% 7%}
    50%{background-position:100% 94%}
    100%{background-position:0% 7%}
}