.cCloseOnAway{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100vw;
    z-index: 5;
}
.citemMenuDD{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-100%, 0);
    background-color: white;
    width: 70vw;
    max-width: 250px;
    max-height: 70vh;
    height: auto;
    overflow-y: scroll;
    z-index: 5;
    border: 1px solid rgba(147, 147, 147, 0.294);
}

.citemMenuDD::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.citemMenuDD::-webkit-scrollbar-thumb {
    background: #000;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.citemMenuDD::-webkit-scrollbar-corner {
    background: #000;
}

.cEachItemMenuDD{
    padding: 2px;
    /* border: 1px solid black; */
    margin: 1px auto;
    text-align: center;
    cursor: pointer;
}

.cEachItemMenuDD:hover{
    background-color: var(--secondaryColor);
    color: var(--letraSecondaryColor);
}


@media screen and (min-width:320px) and (max-width: 500px) {
    .citemMenuDD{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: white;
        width: 100vw;
        max-width: 100vw;
        height: auto;
        max-height: 30vh;
        overflow-y: scroll;
    }
    
    .citemMenuDD::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    
    .citemMenuDD::-webkit-scrollbar-thumb {
        background: #000;
        -webkit-border-radius: 1ex;
        border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
    
    .citemMenuDD::-webkit-scrollbar-corner {
        background: #000;
    }
    
    .cEachItemMenuDD{
        max-width: 90%;
        padding: 2px;
        border: 1px solid black;
        margin: 1px auto;
        text-align: center;
    
    }
}