#vectorBin{
    transform: translate(25%, 25%);
}

#vectorBin{
    fill: white;
}

.contenedorGeneralTarjetas {
    display: flex;
    align-items:baseline;
    justify-content: center;
    min-height: 50vh;
}

.contenedorInternoTarjetas {
    width: 90%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.encabezadoContenedorTarjetas {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    height: 80px;
    padding: 2% 0;
    margin: 0 auto;
    color: var(--primaryColor);
}

.encabezadoContenedorTarjetas > h3{
    font-weight: 200;
}

.encabezadoContenedorTarjetas button {
    padding: 15px;
    font-size: 2rem;
    margin: 15px 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.cButtonAddNewCard{
    width: 200px;
    background-color: var(--secondaryColor);
    color: white;
    border: none;
    padding: 10px 0;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
}

.cButtonAddNewCard:hover{
    background-color: var(--primaryColor);
}

.agrupadorListaTarjetas {
    width: 80%;
}

.c_cerrar_lista_tarjetas{
    width: 90%;
    height: 40px;
    margin-top: 10px;
    display: none;
    justify-content: flex-end;
}

.c_cerrar_lista_tarjetas img{
    width: 10%;
    height: 100%;
}

.tarjetaIndividual{
    margin: 10px auto;
    width: 95%;
    max-width: 450px;
    /* height: 100px; */
    border-radius: 10px;
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
    color: #939395;
    font-size: 90%;
    box-shadow: 0px 0px 3px 1px #00000061;
    text-transform: capitalize;
    overflow: hidden;
}

.tarjetaIndividual h3{
    font-size: 95%;
    font-weight: 200;
}

.numeroTarjetaIndividual{
    width: 90%;
    height: 90%;
    display: grid;
    grid-template-columns: 60% 20% 15%;
    margin: 0 auto;
}

.cDatosTarjetaSHalf{
    height: 90%;
    width: 80%;
    display: grid;
    grid-template-columns: 65% 20% 15%;
    text-transform: uppercase;
    padding-right: 10px;
}

.cDatosTarjetaSHalf h3:nth-child(1){
    color: var(--primaryColor);
}

.eliminarTarjetaIndividual{
    width: 100%;
    height: 100%;
    background-color: #D72630;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.eliminarTarjetaIndividual:hover{
    filter: brightness(120%);
}

.iDatosTarjetaFFHalf{
    width: 90%;
    height: 50%;
    word-break: normal;
}


@media screen and (min-width:320px) and (max-width: 480px) {

    .c_cerrar_lista_tarjetas{
        width: 90%;
        height: 40px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
    
    .c_cerrar_lista_tarjetas img{
        width: 10%;
        height: 100%;
    }
    
    .tarjetaIndividual h3{
        font-size: 90%;
    }

    .encabezadoContenedorTarjetas{
        text-align: center;
    }

    .tarjetaIndividual{
        grid-template-columns: 1fr;
    }

    .numeroTarjetaIndividual {
        gap: 5px;
    }
    
}

@media screen and (min-width:481px) and (max-width: 768px) {

    
}

@media screen and (min-width:769px) and (max-width: 1024px) {

    

}

@media screen and (min-width:1025px) and (max-width: 1200px) {

    


}
