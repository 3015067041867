.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently 
    supported by Chrome, Edge, Opera and Firefox */
}

.contenedor {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin: auto;
    background: transparent;
}

.contenedorHeader {
    display: flex;
    height: auto;
    width: 100%;
    position: sticky;
    top: 0px;
    flex-direction: column;
    background: transparent;
    z-index: 1;
}

.RowHeader.socialMedia {
    height: 40px;
    cursor: pointer;
    flex-direction: row;
    margin-right: 10px;
    justify-content: flex-end;
}

.logoSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    margin-right: 2px;
}

.RowHeader {
    display: flex;
}

.logoHeader {
    height: 100%;
    width: 20%;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgLogoSocial {
    height: auto;
    width: 100%;
}

.imgHeader {
    height: auto;
    width: 100%;
    max-width: 250px;
}

.menu {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--letraPrimaryColor);
    width: 100%;
}

#search {
    color: black;
}

.menuItem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 35px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
    min-width: 100px;
    width: 100%;
    background: var(--primaryColor);
    box-shadow: -12px 0px 0px -11px;
    position: relative;
}

.tituloMenu {
    display: flex;
}

.iconoMenu {
    height: 25px;
    width: 25px;
    margin: auto;
    margin-left: 8px;
    margin-right: 0px;
}

.cItemInicial {
    box-shadow: unset;
}

.menuItem.d2 {
    width: 50%;
}

.menuItem.d3 {
    width: 33%;
}

.menuItem.d4 {
    width: 25%;
}

.menuItem.d5 {
    width: 20%;
}

.menuItem.d6 {
    width: 16.6%;
}

.menuItem.d7 {
    width: 14.2%;
}

.menuItem.d8 {
    width: 12.5%;
}

.menuItem.d9 {
    width: 11.1%;
}

.menuItem.d10 {
    width: 10%;
}

/* .menuItem:hover {
    border-bottom: 2px solid var(--secondaryColor);
} */

#btnMenu {
    padding-right: 0px;
    padding-left: 0px;
    height: 0px;
    width: 0px;
    visibility: hidden;
    display: none;
    color: var(--letraPrimaryColor);
}

.c_menu_productos{
    position: absolute;
    top: 100%;
   /*  background-color: var(--primaryColor); */
    width: 100%;
    /* max-height: 400px;
    overflow-y: auto; */
}

.i_menu_productos_grupos{
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    border: 1px solid var(--letraPrimaryColor);
    margin: 2px auto;
    position: relative;
}

.i_menu_productos_grupos:hover{
    background-color: var(--secondaryColor);
}

.i_menu_productos_grupos:hover .c_menu_productos_subgrupos{
    display: inline-block;
}

.c_menu_productos_subgrupos{
    display: none;
    position: absolute;
    left: 100%;
    top: -1px;
    /* border: 1px solid var(--letraPrimaryColor); */
    width: 100%;
    padding-left: 5px;
}

.i_menu_productos_subgrupos{
    background-color: var(--primaryColor);
    color: var(--letraPrimaryColor);
    position: relative;
    border: 1px solid var(--letraPrimaryColor);
    margin-bottom: 2px;
    
}

.i_menu_productos_subgrupos:hover{
    background-color: var(--secondaryColor);
}

@media (max-width: 960px) {
    .menu {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .RowHeader {
        flex-direction: column;
    }

    .logoHeader {
        width: 100%;
    }

    .menu {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .menu {
        flex-direction: column;
        width: 100%;
        border-bottom: unset;
    }

    #btnMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28px;
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
        cursor: pointer;
        min-width: 100px;
        /*border-bottom: 2px solid rgba(255, 255, 255, 0.233);*/
        width: 100%;
        visibility: unset;
        z-index: 1;
        transition: unset;
        transform: unset;
    }

    .menuItem {
        padding-right: 0px;
        padding-left: 0px;
        visibility: hidden;
        z-index: -100;
        height: 0px;
        transform: translateY(-120%);
        box-shadow: 0px 0px 0px 1px var(--letraPrimaryColor);
        font-size: 13px;
    }

    .menuItem.visible {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28px;
        padding-right: 5px;
        padding-left: 5px;
        cursor: pointer;
        min-width: 100px;
        /*border-bottom: 1px solid white;*/
        z-index: unset;
        visibility: unset;
        width: 100% !important;
        transform: translateX(0%);
        transition-property: height;
        transition-duration: .3s;
        transition-delay: 0s;
        transition-timing-function: ease-out;
    }

    .menuItem:hover,
    .menuItem.visible:hover {
        font-weight: bold;
        border-bottom: unset;
    }

    .RowHeader {
        flex-direction: column;
    }

    .imgLogoSocial {
        height: 30px;
        width: 30px;
    }

    .iconoMenu {
        height: 22px;
        width: 22px;
    }
}

@media (max-width: 250px) {
    .RowHeader.socialMedia {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        height: 40px;
        cursor: pointer;
        flex-direction: row;
        margin-right: 0px;
    }

    .logoSocial {
        width: 38px;
    }
}