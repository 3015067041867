.c_general_confirmacion{
    width: 90%;
    margin: 0 auto;
}

.c_general_informacion_envio{
    width: 100%;
}

.c_titulo_informacion_envio{
    width: 90%;
    margin: 0 auto;
    padding: 0px 10px;
    color: var(--secondaryColor);
    font-size: 16px;
}

.c_titulo_informacion_envio h6{
    font-weight: 600;
}


.c_informacion_envio{
    width: 100%;
    border: 2px solid var(--secondaryColor);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    word-break: break-all;
}

.i_informacion_envio:nth-last-child(1){
    border: none;
}

.i_informacion_envio{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    border-bottom: 2px solid var(--secondaryColor);
}

.i_informacion_envio h6{
    color: var(--primaryColor);
    font-weight: 600;
}

.i_informacion_envio h6:last-child{
    color: var(--secondaryColor);
    text-align: center;
    cursor: pointer;
    
}

.i_informacion_envio h6:last-child:hover{
    color: var(--primaryColor);
}

.c_informacion_pago{
    width: 100%;
    margin: 30px auto;
    height: auto;
}

.i_titulo_informacion_pago{
    width: 90%;
    margin: 0 auto;
    padding: 0px 10px;
    color: var(--secondaryColor);
    font-size: 16px;
}

.i_titulo_informacion_pago h6{
    font-weight: 600;
}

.c_informacion_tarjetahabiente{
    width: 90%;
    margin: 0 auto;
}

.c_informacion_tarjetahabiente label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.c_informacion_tarjetahabiente label h6{
    padding: 0px 10px;
}


.c_informacion_tarjetahabiente input{
    border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_informacion_pago_tarjeta{
    width: 90%;
    margin: 0 auto;
}

.c_informacion_pago_tarjeta label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.c_informacion_pago_tarjeta label h6{
    padding: 0px 10px;
}

.c_informacion_pago_tarjeta input{
    border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_informacion_pago_mes_year_cvv{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    text-align: center;
}

.c_informacion_pago_mes{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c_informacion_pago_mes label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.c_informacion_pago_mes select{
    border: 2px solid var(--secondaryColor);
        width: 80%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_informacion_pago_year{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c_informacion_pago_year select{
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 80%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_informacion_pago_year label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.c_informacion_pago_cvv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c_informacion_pago_cvv input{
    height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 80%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_informacion_pago_cvv label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.c_acciones_informacion_pago{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}

.button_regresar_informacion_pago{
    border: 2px solid var(--secondaryColor);
    padding: 5px 20px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.button_regresar_informacion_pago h6{
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.button_regresar_informacion_pago:hover{
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}



.button_regresar_informacion_pago:hover #vectorArrowL polygon{
    fill: var(--colorLetraSecondaryColor);
}

.button_agregar_tarjeta_informacion_pago {
    border: 2px solid var(--secondaryColor);
    padding: 5px 20px;
    border-radius: 30px;
    color: var(--secondaryColor);
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.button_agregar_tarjeta_informacion_pago h6{
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.button_agregar_tarjeta_informacion_pago:hover {
    background-color: var(--secondaryColor);
    color: var(--colorLetraSecondaryColor);
}

.button_agregar_tarjeta_informacion_pago:hover #vectorSaveIcon path{
    stroke: var(--colorLetraSecondaryColor);
}

.button_agregar_tarjeta_informacion_pago:hover #vectorSaveIcon rect{
    stroke: var(--colorLetraSecondaryColor);
}

.button_agregar_tarjeta_informacion_pago:hover #vectorSaveIcon line{
    stroke: var(--colorLetraSecondaryColor);
}

.c_general_requiere_factura{
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: 30px auto;
}

.c_titulo_requiere_factura{
    width: 90%;
    margin: 0 auto;
    padding: 0px 10px;
    color: var(--secondaryColor);
    font-size: 16px;
}

.c_titulo_requiere_factura h6{
    font-weight: 600;
}

.c_checkbox_factura{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 10px;
}

.c_checkbox_factura input{
    margin: 0;
}

.c_checkbox_factura label{
    padding-left: 10px;
    color: var(--primaryColor);
}

.c_interno_requiere_factura {
    width: 100%;
    border: 2px solid var(--secondaryColor);
    border-radius: 20px;
}

.c_general_datos_facturacion{
    width: 90%;
    height: auto;
    margin: 0 auto;
}

.cg_df{
    width: 100%;
    margin: 20px auto;
}

.df_nombre_razon_social{
    width: 100%;
    margin: 0 auto;
}

.df_nombre_razon_social label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.df_nombre_razon_social label h6{
    padding: 0px 10px;
}

.df_nombre_razon_social input{
    border: 2px solid var(--secondaryColor);
    width: 100%;
    height: 25px;
    border-radius: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    padding: 0px 10px;
    font-size: 90%;
}

.df_rfc{
    width: 100%;
    margin: 0 auto;
}

.df_rfc label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.df_rfc label h6{
    padding: 0px 10px;
}

.df_rfc input{
    border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.cg_direccion_facturacion{
    width: 100%;
    margin: 0 auto;
}

.cg_direccion_facturacion label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.cg_direccion_facturacion label h6{
    padding: 0px 10px;
}

.cg_direccion_facturacion select{
    border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_button_popup_agregarDireccionFact h6{
    padding: 0px 10px;
    font-weight: 800;
    cursor: pointer;
}

.c_button_popup_agregarDireccionFact h6:hover{
    color: var(--secondaryColor);
}

.c_seleccionar_tarjeta{
    width: 100%;
}

.c_titulo_seleccionar_tarjeta{
    width: 90%;
    margin: 0 auto;
    padding: 0px 10px;
    color: var(--secondaryColor);
    font-size: 16px;
}

.i_seleccionar_tarjeta{
    width: 100%;
    border: 2px solid var(--secondaryColor);
    border-radius: 20px;
    margin: 0 auto;
    
}

.c_agrupador_tarjeta{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 0px;
    width: 90%;
}

.i_seleccionar_tarjeta label{
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
}

.i_seleccionar_tarjeta label h6{
    padding: 0px 10px;
}

.i_seleccionar_tarjeta select{
    border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
}

.c_text_seleccionar_tarjeta{
    text-align: center;
    width: 100%;
}

.c_text_seleccionar_tarjeta b{
    cursor: pointer;
}

.c_acciones_agregar_informacion_pago{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}




@media screen and (min-width:320px) and (max-width: 480px) {

    label {
        width: 90%;
    }

    .c_general_informacion_envio {
        width: 100%;
        max-width: 480px;
    }

    .c_informacion_envio {
        width: 100%;
        border: 2px solid var(--secondaryColor);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .i_informacion_envio {
        width: 90%;
        font-size: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: 2px solid var(--secondaryColor);
    }

    .c_interno_requiere_factura {
        width: 100%;
        border: 2px solid var(--secondaryColor);
    }

    .df_nombre_razon_social {
        width: 100%;
        margin: 0 auto;
    }

    .df_nombre_razon_social input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .df_rfc input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .cg_direccion_facturacion select {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_tarjeta input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_mes_year_cvv {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        text-align: start;
    }

    .c_informacion_pago_mes select {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_year select {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_cvv input {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

}

@media screen and (min-width:481px) and (max-width: 768px) {

    label {
        width: 90%;
    }

    .c_general_informacion_envio {
        width: 100%;
        max-width: 480px;
    }

    .c_informacion_envio {
        width: 100%;
        border: 2px solid var(--secondaryColor);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .i_informacion_envio {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: 2px solid var(--secondaryColor);
    }

    .c_interno_requiere_factura {
        width: 100%;
        border: 2px solid var(--secondaryColor);
    }

    .df_nombre_razon_social {
        width: 100%;
        margin: 0 auto;
    }

    .df_nombre_razon_social input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .df_rfc input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .cg_direccion_facturacion select {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_tarjeta input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_mes_year_cvv {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        text-align: start;
    }

    .c_informacion_pago_mes select {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_year select {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_cvv input {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 100%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }
}

@media screen and (min-width:769px) and (max-width: 1024px) {

    

    label {
        width: 90%;
    }

    .c_general_confirmacion{
        width: 90%;
        margin: 0 auto;
    }

    .c_general_informacion_envio {
        width: 100%;
        margin: 0 auto;
    }

    .c_informacion_envio {
        width: 100%;
        border: 2px solid var(--secondaryColor);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .i_informacion_envio {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: 2px solid var(--secondaryColor);
    }

    .c_interno_requiere_factura {
        width: 100%;
        border: 2px solid var(--secondaryColor);
    }

    .df_nombre_razon_social {
        width: 100%;
        margin: 0 auto;
    }

    .df_nombre_razon_social input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .df_rfc input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .cg_direccion_facturacion select {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_tarjeta input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_mes_year_cvv {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .c_informacion_pago_mes select {
        border: 2px solid var(--secondaryColor);
        width: 80%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_year select {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 80%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_cvv input {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 80%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

}

@media screen and (min-width:1025px) and (max-width: 1200px) {

    label {
        width: 90%;
    }

    .c_general_confirmacion{
        width: 90%;
        margin: 0 auto;
    }

    .c_general_informacion_envio {
        width: 100%;
        margin: 0 auto;
    }

    .c_informacion_envio {
        width: 100%;
        border: 2px solid var(--secondaryColor);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .i_informacion_envio {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        border-bottom: 2px solid var(--secondaryColor);
    }

    .c_interno_requiere_factura {
        width: 100%;
        border: 2px solid var(--secondaryColor);
    }

    .df_nombre_razon_social {
        width: 100%;
        margin: 0 auto;
    }

    .df_nombre_razon_social input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .df_rfc input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .cg_direccion_facturacion select {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_tarjeta input {
        border: 2px solid var(--secondaryColor);
        width: 100%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_mes_year_cvv {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .c_informacion_pago_mes select {
        border: 2px solid var(--secondaryColor);
        width: 80%;
        height: 25px;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_year select {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 80%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

    .c_informacion_pago_cvv input {
        height: 25px;
        border: 2px solid var(--secondaryColor);
        width: 80%;
        border-radius: 30px;
        color: var(--primaryColor);
        font-weight: 600;
        padding: 0px 10px;
        font-size: 90%;
    }

}